import React, { useContext, useState } from 'react';
import axios from 'axios';
import Context from '../../context';

const QrCodeNew = () => {
   
  let { domainData, token } = useContext(Context);
  domainData = JSON.parse(domainData);

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');


 let arr= domainData?.inst_profile?.business_logo.split('/')
 
console.log('domainData?.inst_profile?.business_logo :>> ', domainData?.inst_profile?.business_logo,arr[arr?.length-1]);
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFileUpload = async () => {
  
    try {


      const res = await axios.post('http://127.0.0.1:8000/api/auth/download-inst-logo', {imageUrl:domainData?.inst_profile?.business_logo,imageName:arr[arr?.length-1]});
      setMessage(res.data.message);
    } catch (error) {
      console.error('There was an error uploading the file!', error);
      setMessage('File upload failed');
    }
  };

  return (
    <div>
      <h2>File Upload</h2>
      <input type="file" onChange={onFileChange} />
      <button onClick={onFileUpload}>Upload</button>
      <p>{message}</p>
    </div>
  );
};

export default QrCodeNew;




