import {
    Card,
    Form,
    Row,
    Col,
    Button,
    ButtonGroup,
    Tab,
    Tabs,
    Nav,
    ProgressBar,
  } from "react-bootstrap";
  import Layout from "../../../../layout/default";
  import { Block, Icon } from "../../../../components";
  import { useContext } from "react";
  import ModalContext from "../../../../context/ModalContext";
  import { useState, useEffect } from "react";
  
  function PracticeTitleTimeForAudio({ selected_ques_Obj }) {
    let { timeForPractice, setTimeForPractice } = useContext(ModalContext);
    const[iniatialPrepare,setiniatialPrepare] = useState(false)
    let { prepare, remain ,initialprepare} = timeForPractice;
    const [time, setTime] = useState({
      minutes:0,
      seconds:1,
    });
    const[firstTimeRender,setFirstTimeRender] = useState(false)
    const [formattedTime, setFormattedTime] = useState( "00:02");
    var timerInterval;
    console.log("initialprepare",timeForPractice)
    useEffect(() => {
      setFirstTimeRender(true)
         timerInterval = setInterval(() => {
          if (time.minutes === 0 && time.seconds === 0) {
            if(prepare?.status){
              setTimeForPractice({...timeForPractice,prepare:{...timeForPractice.prepare,status:false},stopped:true,time:{...timeForPractice.time,status:false}})
              const [min, sec] = (
                prepare.status
                  ? prepare.time
                  : timeForPractice.time.status
                    ? timeForPractice.time.time
                    : remain.status
                      ? remain.time
                      : "00:00"
              )
                .split(":")
                .map(Number);
                setFormattedTime(
                  `${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : time.seconds}`
                );
              setTime({minutes: min, seconds: sec});

            }           
            clearInterval(timerInterval);
            return
          }  else if (time.minutes === 0) {
            const newSeconds = Math.max(0, time.seconds - 1);
            setTime({minutes: 0, seconds: newSeconds});
          } else if (time.minutes !== 0 && time.seconds === 0) {
            setTime({minutes: time.minutes - 1, seconds: 59});
          } else {
            setTime({minutes: time.minutes, seconds: time.seconds - 1});
          }
      
          // Update the formatted time
          setFormattedTime(
            `${time.minutes < 10 ? "0" + time.minutes : time.minutes}:${time.seconds < 10 ? "0" + time.seconds : time.seconds}`
          );
        }, 1000);
        return () => clearInterval(timerInterval);
      }, [time, timeForPractice, iniatialPrepare]);

      console.log("FORMATED TIME",formattedTime);

      useEffect(()=>{        
        if(firstTimeRender){
          setFormattedTime(
            prepare.status
              ? prepare.time
              : timeForPractice.time.status
                ? timeForPractice.time.time
                : remain.status
                  ? remain.time
                  : "00:00"
          );
          const [min, sec] = (
            prepare.status
              ? prepare.time
              : timeForPractice.time.status
                ? timeForPractice.time.time
                : remain.status
                  ? remain.time
                  : "00:00"
          )
            .split(":")
            .map(Number);
          setTime({minutes: min, seconds: sec});
        clearInterval(timerInterval);
      
      }
      },[timeForPractice.time.status,selected_ques_Obj])
    
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <span className="text-secondary">
            {selected_ques_Obj.tags}
          </span>
          <div>
            {" "}
            <Icon name="bookmark-fill" variant="assigned"></Icon>{" "}
            <Button variant="assigned" size="sm" className="ms-3">
              Tested (20)
            </Button>
          </div>
        </div>
        {timeForPractice?.time?.status && (
          <p className="text-hidanger mb-3">Time : {formattedTime}</p>
        )}
        {prepare.status && (
          <p className="text-hidanger mb-3">Prepare : {formattedTime} </p>
        )}
           {
             (!timeForPractice?.time?.status && !prepare.status) &&  <p className="text-hidanger mb-3">Time : {selected_ques_Obj?.answer_time} </p>
        }
      </>
    );
  }
  
  export default PracticeTitleTimeForAudio;
  