import React, { useState, useEffect, useContext } from "react";
import ModalContext from "../../../context/ModalContext";

function Timer({ startRecording,timer ,NoRecording}) {
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [minutes, seconds] = timer.split(":").map(Number);

  const [time, setTime] = useState({
    minutes,
    seconds,
  });
  const [message, setMessage] = useState("");


  useEffect(()=>{
setPopUpType("prepare")
  },[])
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timerInterval);
         startRecording();
        setPopUpType("default")
        setMessage("");
      } else if (time.minutes === 0 && time.seconds === 1) {
        setMessage("The Clip will start in 1 Second.");
        setTime({ minutes: 0, seconds: 0 });
      } else if (time.minutes === 0) {
        const newSeconds = Math.max(0, time.seconds - 1);

        setMessage(
          `The Clip will start in ${newSeconds} Second${
            newSeconds === 1 ? "" : "s"
          }.`
        );
        setTime({ minutes: 0, seconds: newSeconds });
      } else if (time.minutes !== 0 && time.seconds === 0) {
        setMessage(`The Clip will start in ${time.minutes} Minute.`);
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      } else {
        const minuteText = time.minutes === 1 ? "Minute" : "Minutes";
        setMessage(
          `The Clip will start in ${time.minutes} ${minuteText} ${
            time.seconds
          } Second${time.seconds === 1 ? "" : "s"}.`
        );
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [time]);

  return (
    <>
    <div>
    {time.minutes !== 0 || time.seconds !== 0 && (
        <span className="border border-primary rounded-circle py-1 px-2 mx-3">
          {time.seconds < 10 ? `${time.seconds}` : time.seconds}
        </span>
      )} 
       {message}
      </div>
    </>
  );
}

export default Timer;
