import React from "react";

function ResultTab({
  Col,
  Card,
  Row,
  Tab,
  Block,
  speaking_data,
  reading_data,
  writing_data,
  listening_data,
  overall_result,
  Navbar,
  exam
}) {
  return (
    <>
      <Block>
        <Tab.Container id="pills-result" defaultActiveKey="analytics-speaking">
          <Navbar
            speaking_data={speaking_data}
            reading_data={reading_data}
            writing_data={writing_data}
            listening_data={listening_data}
          />
          <Tab.Content>
            <Tab.Pane eventKey="analytics-speaking">
              <Row className="g-gs">
                <Col md="4">
                  <Card>
                    <Card.Body>
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <div className="d-flex justify-content-between">
                            <h5> {exam?.name}</h5>
                            <span className="text-light small fw-bold bg-primary-soft px-2 rounded">
                              Completed
                            </span>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <div className="d-flex flex-column">
                              <b className="text-light small">Duration</b>
                              <b className="text-secondary fw-bold">{exam?.total_time}</b>
                            </div>
                            <div className="d-flex flex-column">
                              <b className="text-light small">
                                No. of Questions
                              </b>
                              <b className="text-secondary fw-bold">{exam?.questID.length}</b>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <b className="text-secondary my-0">Overall Score</b>
                            <b className="text-secondary my-0">{overall_result?.overall_score}/90</b>
                          </div>
                          <div className="d-flex justify-content-between">
                            <b className="text-light my-0">Submitted at:</b>
                            <b className="text-secondary my-0">
                              {reading_data?.submitted_at}
                            </b>
                          </div>
                          <div className="border-top d-flex justify-content-center mt-3">
                            <div className="d-flex flex-column gap-3 p-4 border-end justify-content-center">
                              <img
                                src="/images/result.svg"
                                style={{ width: "40px" }}
                              ></img>
                              <h5>Result</h5>
                            </div>
                            <div className="d-flex flex-column gap-3 p-4 border-end justify-content-center">
                              <img
                                src="/images/answer.svg"
                                style={{ width: "40px" }}
                              ></img>
                              <h5>Answer</h5>
                            </div>
                            <div className="d-flex flex-column gap-3 p-4 justify-content-center">
                              <img
                                src="/images/analytics.svg"
                                style={{ width: "40px" }}
                              ></img>
                              <h5>Analytics</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="results-writing">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>Writing test</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="results-reading">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>Reading test</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="results-listening">
              <Row className="g-gs">
                <Col md="12">
                  <Card>
                    <Card.Body>Listening test</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Block>
    </>
  );
}

export default ResultTab;
