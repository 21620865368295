import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function AISpeechRecognition() {

  return (
    <>
      <div className='d-flex gap-2 flex-wrap p-3 text-secondary'>
        <span className='me-5'>AI Speech Recognition</span>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-success rounded-circle"></span> <span>Good</span></div>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-warning rounded-circle"></span> <span>Average</span></div>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-danger rounded-circle"></span> <span>Bad</span></div>
        <div className='d-flex align-items-center gap-1'><span>/</span> <span>Pause</span></div>
      </div>
      <p className='p-3 text-middark fw-bold'>
        <span className='text-success'>Lorem ipsum</span> <span className='text-danger'>dolor sit amet,</span> <span className='text-warning'>consectetur adipiscing elit,</span> <span className='text-danger'>sed do eiusmod</span> <span>/</span> <span className='text-success'>tempor incididunt</span> <span className='text-danger'>ut labore Ut enim</span> <span>/</span> <span className='text-warning'>ad minim veniam,</span> <span className='text-success'>quis nostrud</span> <span className='text-warning'>exercitation ullamco laboris</span> <span>/</span> <span className='text-danger'>aliquip ex ea</span> <span className='text-warning'>commodo con</span> <span className='text-success'>Duis aute irure</span> <span>/</span> <span className='text-warning'>dolor in reprehenderit in</span> <span>/</span> <span className='text-success'>voluptate velit esse</span> <span className='text-danger'>cillum dolore eu</span> <span className='text-warning'>fugiat nulla pariatu</span>
      </p>
    </>
  );
}
export default AISpeechRecognition;