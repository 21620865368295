import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button } from "react-bootstrap";
import ModalContext from "../../context/ModalContext";
// kanban basic
export function DragDropBasic({ containerClass, data, ...props }) {
  let {
    popUpType,
    answerNull,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  const [items, setItems] = useState([]);
  const [data1, setDatasets] = useState([]);
  const [groups, setGroups] = useState({});

  useEffect(() => {
    // Mock an API call.
    buildAndSave(data);
  }, [data]);

  function buildAndSave(items) {
    const groups = {};

    for (let i = 0; i < Object.keys(items)?.length; ++i) {
      const currentGroup = items[i];
      groups[currentGroup.id] = i;
    }

    // Set the data.
    setItems(items);

    // Makes the groups searchable via their id.
    setGroups(groups);
  }
  //   useEffect(()=>{
  //     setTimeout(()=>{
  //       setAnswerJson({...answerJson,anwer:data1})
  //     },1000)
  // // setAnswerJson({...answerJson,anwer:data1})
  //   },[data1])


  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source, type } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }

        if ("group" === type) {
          const sourceIndex = source.index;
          const targetIndex = destination.index;

          const workValue = items.slice();
          const [deletedItem] = workValue.splice(sourceIndex, 1);
          workValue.splice(targetIndex, 0, deletedItem);

          buildAndSave(workValue);

          return;
        }

        const sourceDroppableIndex = groups[source.droppableId];
        const targetDroppableIndex = groups[destination.droppableId];
        const sourceItems = items[sourceDroppableIndex].items.slice();
        const targetItems =
          source.droppableId !== destination.droppableId
            ? items[targetDroppableIndex].items.slice()
            : sourceItems;

        // Pull the item from the source.
        const [deletedItem] = sourceItems.splice(source.index, 1);
        targetItems.splice(destination.index, 0, deletedItem);

        const workValue = items.slice();
        workValue[sourceDroppableIndex] = {
          ...items[sourceDroppableIndex],
          items: sourceItems,
        };
        workValue[targetDroppableIndex] = {
          ...items[targetDroppableIndex],
          items: targetItems,
        };

        setItems(workValue);
        setDatasets(workValue);
      }}
    >
      <Droppable droppableId="ROOT" type="group">
        {(provided) => (
          <div
            className={`kanban-container exam-kanban flex-nowrap ${
              containerClass ? containerClass : ""
            }`}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <DroppableList setItemItems={(e)=>setItems(e)}maindata={items} key={item.id} index={index} {...item} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

// kanban custom
// export function KanbanCustom({ containerClass, data, ...props }) {
//   const [items, setItems] = useState([]);
//   const [groups, setGroups] = useState({});

//   useEffect(() => {
//     // Mock an API call.
//     buildAndSave(data);
//   }, [data]);

//   function buildAndSave(items) {
//     const groups = {};
//     for (let i = 0; i < Object.keys(items)?.length; ++i) {
//       const currentGroup = items[i];
//       groups[currentGroup.id] = i;
//     }

//     // Set the data.
//     setItems(items);

//     // Makes the groups searchable via their id.
//     setGroups(groups);
//   }

//   return (
//     <DragDropContext
//       onDragEnd={(result) => {
//         const { destination, source, type, } = result;

//         if (!destination) {
//           return;
//         }

//         if (destination.droppableId === source.droppableId && destination.index === source.index) {
//           return;
//         }

//         if ('group' === type) {
//           const sourceIndex = source.index;
//           const targetIndex = destination.index;

//           const workValue = items.slice();
//           const [deletedItem,] = workValue.splice(sourceIndex, 1);
//           workValue.splice(targetIndex, 0, deletedItem);

//           buildAndSave(workValue);

//           return;
//         }

//         const sourceDroppableIndex = groups[source.droppableId];
//         const targetDroppableIndex = groups[destination.droppableId];
//         const sourceItems = items[sourceDroppableIndex].items.slice();
//         const targetItems = source.droppableId !== destination.droppableId ? items[targetDroppableIndex].items.slice() : sourceItems;

//         // Pull the item from the source.
//         const [deletedItem,] = sourceItems.splice(source.index, 1);
//         targetItems.splice(destination.index, 0, deletedItem);

//         const workValue = items.slice();
//         workValue[sourceDroppableIndex] = {
//           ...items[sourceDroppableIndex],
//           items: sourceItems,
//         };
//         workValue[targetDroppableIndex] = {
//           ...items[targetDroppableIndex],
//           items: targetItems,
//         };

//         setItems(workValue);
//       }}
//     >
//       <Droppable droppableId='ROOT' type='group'>
//         {(provided) => (
//           <div
//             className={`kanban-container ${containerClass ? containerClass : ''}`}
//             {...provided.droppableProps}
//             ref={provided.innerRef}
//           >
//             {items.map((item, index) => (
//               <DroppableList
//                 key={item.id}
//                 index={index}
//                 {...item}
//               />
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </DragDropContext>
//   );
// }

// kanban drop list
function DroppableList({ maindata,id, items,setItemItems, label, theme }) {
  let {
    popUpType,
    answerNull,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  const [list, setList] = useState( []  );
  useEffect(()=>{
    setList(maindata)
  },[maindata])
  const [selectedItem, setSelectedItem] = useState(null);
  const [secondBoxItems, setSecondBoxItems] = useState([]);
  const [action, setAction] = useState(null);
  const handleItemClick = (item, activate) => {
    setSelectedItem(item);
    setAction(activate);
  }; 
  console.log("selectedItem",selectedItem)

  
  const handleMoveItemLeft = (action) => {
  
    if (selectedItem ) {
      alert("*")
      const listIndex = list.findIndex((listItem) =>
      listItem.items.find((item) => item?.id === selectedItem?.id)
    );
    console.log("listIndex",listIndex)

    if (listIndex !== -1) {
      console.log("selectedItem",selectedItem)
      // Remove the selected item from the list
      const updatedList = [...list];
      console.log("------",updatedList[listIndex].items)
      updatedList[listIndex].items = updatedList[listIndex].items.filter(
        (item) => item?.id !== selectedItem?.id
      );
console.log("updatedList",updatedList)
      // Find the index of the second list
      const secondListIndex = list.findIndex((listItem) => listItem.id === "item-1");
      console.log("updatedList",secondListIndex)
      if (secondListIndex !== -1) {
        // Add the selected item to the second list
        updatedList[secondListIndex].items = [
          ...updatedList[secondListIndex].items,
          selectedItem
        ];
        setItemItems(updatedList)
        // Update the state with the modified list
        setList(updatedList);

        // Reset selected item and action
        // setSelectedItem(null);
        // setAction(null);
      }
      setSelectedItem(null);
    }
    }
  };

  const handleMoveItemRight = (action) => {
      if (selectedItem ) {
      const listIndex = list.findIndex((listItem) =>
        listItem.items.some((item) => item === selectedItem)
      );
      console.log("listIndex",listIndex)
  
      if (listIndex !== -1) {
        // Remove the selected item from the list
        const updatedList = [...list];
        updatedList[listIndex].items = updatedList[listIndex].items.filter(
          (item) => item !== selectedItem
        );
  
        // Find the index of the second list
        const secondListIndex = list.findIndex((listItem) => listItem.id === "item-2");
  
        if (secondListIndex !== -1) {
          // Add the selected item to the second list
          updatedList[secondListIndex].items = [
            ...updatedList[secondListIndex].items,
            selectedItem
          ];
          setItemItems(updatedList)
          // Update the state with the modified list
          setList(updatedList);
  
          // Reset selected item and action
          // setSelectedItem(null);
          // setAction(null);
        }
      }
    }
  };

console.log("list",list)
  const moveItemUp = () => {
    if (!selectedItem || secondBoxItems.length < 2) return;

    const index = secondBoxItems.findIndex((item) => item === selectedItem);
    if (index > 0) {
      const updatedItems = [...secondBoxItems];
      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[index - 1];
      updatedItems[index - 1] = temp;
      setSecondBoxItems(updatedItems);
    }
  };

  // Function to handle moving item down within Box 2
  const moveItemDown = () => {
    if (!selectedItem || secondBoxItems.length < 2) return;

    const index = secondBoxItems.findIndex((item) => item === selectedItem);
    if (index < secondBoxItems.length - 1) {
      const updatedItems = [...secondBoxItems];
      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[index + 1];
      updatedItems[index + 1] = temp;
      setSecondBoxItems(updatedItems);
    }
  };


  return (
    <>
      <Droppable droppableId={id}>
        {(provided) => (
          <>
            <div
              className="kanban-item-wrap w-40"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div className={`kanban-board ${theme ? theme : ""}`}>
                <header className="kanban-board-header">
                  <div className="kanban-title-board">
                    <div className="kanban-title-content">
                      <h6 className="title">{label}</h6>
                      <span className="count">{items?.length}</span>
                    </div>
                  </div>
                </header>
                <div className="kanban-drag" id={id == "item-1" ?"leftId":"rightId"}>
                  {items.map((item, index) => (
                    <Draggable
                      draggableId={item.id}
                      index={index}
                      key={index}
                    >
                      {(provided,i) => (
                        <div className={selectedItem?.id === item?.id ? "drag-highlight" : ""}>
                        <div
                          onClick={() => {setSelectedItem(item)}}
                          className={ "kanban-item"}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {item.title && (
                            <div className="kanban-item-title">
                              <h6 className="title">{item.title}</h6>
                            </div>
                          )}
                          {item.content && (
                            <div className="kanban-item-text">
                              <p>{item.content}</p>
                            </div>
                          )}
                        </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            </div>
            {id == "item-1" && (
              <div className="center-kanban">
                <Button onClick={()=>handleMoveItemLeft("right")} variant="transparent">
                  <img src="/images/exam-left-arrow.svg"></img>
                </Button>
                <Button onClick={()=>handleMoveItemRight("left")}  variant="transparent">
                  <img src="/images/exam-right-arrow.svg"></img>
                </Button>
              </div>
            )}
            {id == "item-2" && (
              <div className="end-kanban">
                <Button onClick={moveItemUp} variant="transparent">
                  <img src="/images/exam-top-arrow.svg" alt="Move up" />
                </Button>
                <Button onClick={moveItemDown} variant="transparent">
                  <img src="/images/exam-bottom-arrow.svg" alt="Move down" />
                </Button>
              </div>
            )}
          </>
        )}
      </Droppable>
    </>
  );
}
