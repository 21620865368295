import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import React, { useState } from "react";
export const CountryState = () => {
    
  const [state, setState] = useState({
    country: "",
    region: ""
  })

  const selectCountry = (val) => {
    setState({ ...state, country: val });
  }

  const selectRegion = (val) =>{
   setState({ ...state, region: val });
  }
  const { country, region } = state;

  return(
    <div>
    <div>
 <CountryDropdown
          value={country}
          onChange={(val) => selectCountry(val)} />


        <RegionDropdown
          country={country}
          value={region}
          onChange={(val) => selectRegion(val)} />
         </div>
      </div>



);
}



// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
// import React, { useState } from "react";

// const CountryState = () => {
//   const [state, setState] = useState({
//     country: "",
//     region: ""
//   });

//   const selectCountry = (val) => {
//     setState({ ...state, country: val });
//   };

//   const selectRegion = (val) => {
//     setState({ ...state, region: val });
//   };

//   const { country, region } = state;

//   return (
//     <div>
//       <div>
//         <CountryDropdown
//           value={country}
//           onChange={(val) => selectCountry(val)}
//         />
//         <RegionDropdown
//           country={country}
//           value={region}
//           onChange={(val) => selectRegion(val)}
//         />
//       </div>
//     </div>
//   );
// };
// export default CountryState;
