import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function YourAnswerWriting() {

  return (
    <div className='bg-white border rounded h-100'>
      <div className='bg-hilight text-secondary p-3 d-flex justify-content-between align-items-start'> <span>Your Answer</span><Button variant='primary' size="sm">Score Info 3/7</Button></div>
      <p className='p-3 text-middark'>Lorem ipsum <span className='bg-danger text-white'>dolor</span> sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minim veniam, quis nostrud <span className='bg-danger text-white'>exercitation</span> ullamco laboris nisi ut aliquip ex ea commodo con
        Duis aute irure dolor in <span className='bg-danger text-white'>reprehenderit</span> in voluptate velit esse cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id es Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to Nemo enim ipsam voluptatem quia</p>
      <div className='bg-hilight text-secondary d-flex gap-2 flex-wrap p-3'>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-secondary rounded-circle"></span> <span>Total Words: 71</span></div>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-warning rounded-circle"></span> <span>Grammar/Style/Structure Error: 0</span></div>
        <div className='d-flex align-items-center gap-1'><span className="p-1 bg-danger rounded-circle"></span> <span>Spelling Error : 9</span></div>
      </div>
    </div>
  );
}
export default YourAnswerWriting;