import { City, Country, State } from "country-state-city";
import { useEffect, useState } from "react";

const CountryState2 = () => {
  let countryData = Country.getAllCountries();
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [country, setCountry] = useState(countryData[0]);
  const [state, setState] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    if (country) {
      setStateData(State.getStatesOfCountry(country.isoCode));
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      console.log("afdasf",City.getCitiesOfState(country.isoCode, state.isoCode))
      setCityData(City.getCitiesOfState(country.isoCode, state.isoCode));
    }
  }, [state]);

  useEffect(() => {
    if (stateData.length > 0) {
      setState(stateData[0]);
    } else {
      setState(null);
    }
  }, [stateData]);

  // useEffect(() => {
  //   if (cityData.length > 0) {
  //     setCity(cityData[0]);
  //   } else {
  //     setCity(null);
  //   }
  // }, [cityData]);
  console.log('cityData2323 :>> ', cityData);

  return (
    <section>
      <div>
        <h2>Country, State and City Selectors</h2>
        <br />
        <div>
          <div>
            <p>Country:</p>
            <select
              value={country?.isoCode || ""}
              onChange={(e) =>
                setCountry(countryData.find(c => c.isoCode === e.target.value))
              }
            >
              {countryData.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          {stateData.length > 0 && (
            <div>
              <p>State:</p>
              <select
                value={state?.isoCode || ""}
                onChange={(e) =>
                  setState(stateData.find(s => s.isoCode === e.target.value))
                }
              >
                {stateData.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {cityData.length > 0 && (
            <div>
              <p>City:</p>
              <select
                value={city?.name || ""}
                onChange={(e) =>
                  setCity(cityData.find(c => c.name === e.target.value))
                }
              >
                {cityData.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CountryState2;
