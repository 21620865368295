import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function YourAnswerReading() {

  return (
    <div className='bg-white border rounded h-100'>
      <div className='bg-hilight text-secondary p-3 d-flex justify-content-between align-items-start'> <span>Your Answer</span><Button variant='primary' size="sm">Score Info 3/7</Button></div>
      <p className='p-3 text-middark'>Companies are struggling to compete in the market due to the availability of different types of competitors with those competitors providing similar products or <span className='bg-danger text-white'>Select <Icon name="downward-ios"></Icon></span> products. In addition, consumers nowadays are becoming more critical in term of their needs and want. <span className='bg-danger text-white'>Select <Icon name="downward-ios"></Icon></span> , the competition is getting harder day after day. Moreover, with so much variety of products and services, companies have to give more attention to getting the required competitive advantage. Competitive advantage is about how the company is trying to compete in the market. Therefore, it is the <span className='bg-danger text-white'>Select <Icon name="downward-ios"></Icon></span> of the benefits that the company is going to be able to deliver for its consumers while others are not. However, some studies have identified some factors that may affect the competitive advantage of companies such as the provided quality, cost reduction, delivery time, <span className='bg-danger text-white'>Select <Icon name="downward-ios"></Icon></span> , and flexibility to do changes to products or services whenever it is needed. Accordingly, selling the product or service at a lower price than the competitors or selling products or services with higher quality than others' products and services will create a good competitive advantage for the company.</p>
    </div>
  );
}
export default YourAnswerReading;