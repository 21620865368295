import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function AllQuestionHeading() {

  return (
    <div className='d-flex justify-content-between align-items-start bg-primary-soft m-0 p-3'>
      <div style={{ width: "85%" }}>
        <h5 className='text-secondary'>Read Aloud</h5>
        <p className='text-middark'>Look at the text below. In 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 40 seconds to read aloud.</p>
      </div>
      <Button variant='secondary' size="sm">Tips & Tricks</Button>
    </div>
  );
}
export default AllQuestionHeading;