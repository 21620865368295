import React from "react";
import { useContext } from "react";
import { useState } from "react";
import ModalContext from "../../../../context/ModalContext";
import { useEffect } from "react";

const DropTargetComponent = (props) => {
  let {
    popUpType,
    setPopUpType,
    setAnswerJson,
    answerJson,
    manageRecordings,
    setManageRecordings,
  } = useContext(ModalContext);
  const [dataArray, setDataArray] = useState([]);
  const handleAllowDrop = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("Text");
    const element = document.getElementById(data);
    e.target.parentNode.replaceChild(element, e.target);
    element.className = "";
    setDataArray((prevDataArray) => [...prevDataArray, data]);
  };
  return (
    <span id={props.id} onDrop={handleDrop} onDragOver={handleAllowDrop}>
      {props.children}
    </span>
  );
};

export default DropTargetComponent;
