import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
import AISpeechRecognition from './AISpeechRecognition';
function YourAnswerSpeaking() {

  return (
    <div className='bg-white border rounded h-100'>
      <div className='bg-hilight p-3 d-flex justify-content-between align-items-start text-secondary'> <span>Your Answer</span><Button variant='primary' size="sm">Score Info 3/7</Button></div>
      <AISpeechRecognition />
      <div className='d-flex flex-column gap-2 justify-content-center align-items-center my-2'>
        <h5>Your Audio Answer</h5>
        <audio src="/images/audio.wav" controls ></audio>
      </div>
    </div>
  );
}
export default YourAnswerSpeaking;