import React, { useRef } from "react";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
function VoiceControl({ audio }) {
  const audioRef = useRef(null);
  function handleVolumeChange(val) {
    audioRef.current.volume = Number(val[0]) / 100;
  }

  return (
    <>
      <img src="/images/exam-volume.svg" className="exam-btn"></img>
      <audio
        className="d-none"
        ref={audioRef}
        src={audio}
        controls
      ></audio>
      <div className="w-100 hirange">
        <NoUiSlider
          tooltip={true}
          range={{ min: 0, max: 100 }}
          start={100}
          onSlide={(value) => handleVolumeChange(value)}
          id="Tooltip-Range"
        />
      </div>
    </>
  );
}

export default VoiceControl;
