import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function QuestionAudio() {

  return (
    <div className='bg-white border rounded h-100 d-flex flex-column justify-content-between'>
      <div className='bg-hilight text-secondary p-3 d-flex justify-content-between align-items-start'>
        <span>Question Audio</span><Button variant='success' size="sm"><span>Go to Question</span> <Icon name="forward-ios"></Icon></Button>
      </div>
      <div className='d-flex justify-content-center my-5'>
        <audio src="/images/audio.wav" controls ></audio>
      </div>
    </div>
  );
}
export default QuestionAudio;