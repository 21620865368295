import React, { useState } from 'react'
import PhoneFlag from "../../components/Form/PhoneFlag";
import CountdownTimer from "../../components/Form/CountdownTimer";
import AuthApi from '../../api/auth';
import Swal from 'sweetalert2';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
const FlagAndMobileOtp = ({data,userData,handleChange,errors,setCountryNumber,countryNumber}) => {

  console.log('countryNumbedatar :>> ', data);
  
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState(null);
  const [VisibleDiv, setVisibleDiv] = useState(false);
  const [timer,setTimer] = useState(false);
  const [optError, setOptError] = useState("")
  const [countStep, setCountStep] = useState(1);
  const [nextDisable, setDisable] = useState(true);
  let api = new AuthApi();
  async function generateRandomNumber() {
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .send_mobile_otp({
        mobile: data?.mobile,
        request_id: randomNumber,
        name: data.name,
        email:data.email,
        institute_id:userData?._id,
        type : "enquiry_form",
        country_code:countryNumber
      })
      .then((res) => {
        console.log("otpres", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });
          setOtp(randomNumber);
          setVisibleDiv(true);
          setTimer(false)
      
        }
           else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.message,
          });
          if(res.message == "OTP attempts exceeded. Try Again After 3 minutes."){

            setTimer(true)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const verifyOtp = async () => {
    await api
      .validate_mobile_otp({ request_id: otp, otp: data.otp, name: data.name })
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: res.msg,
          });
          setCountStep(countStep + 1);
          setDisable(!nextDisable);
          setVerified(true);
          setVisibleDiv(false)
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log( data?.country_code,'dfas',data)

  return (

                                
<>
    <div className="d-flex gap-1">
    {/* <div className="w-40">
      <Form.Group className="form-group ">
        <Form.Label htmlFor="tag" className="control-label">Mobile No.</Form.Label>

       {data?.country_code && (
  <PhoneFlag setCountryNumber={setCountryNumber} selectedCountryCode={data.country_code} />
)} 

     
      </Form.Group>
    </div> */}
    <div className="w-100">
      <Form.Group className="form-group mt-3 ">
      <Form.Label htmlFor="tag" className="control-label">Mobile No.</Form.Label>


        <div className="form-control-wrap">
          <Form.Control
            type="tel"
            maxLength={10}
            disabled={verified }
            id="phone"
            name="mobile"
         placeholder="Mobile No."
            // value={data.phone}
            value={data?.mobile?.length < 11 ? data?.mobile : ""}
            onChange={(e) => handleChange(e)}
            isInvalid={!!errors?.mobile}
          />
          {/* <Form.Control.Feedback type="invalid">
            {errors?.mobile}
          </Form.Control.Feedback> */}
        </div>
      </Form.Group>
    </div>
  </div>
              


                
    <>

      <div className="d-flex gap-1">
        <div className="w-60 "></div>
        <div className="w-40 ">
        {
verified ?
                  <Button
                  variant="outline-primary"
                  className={`border ${verified ? `border-success` :`border-primary`} border-2 fw-bold mt-2 w-75 `}
                  disabled={verified }
                  onClick={() => {
                      if (
                        data?.mobile?.length == 10 &&
                        data?.email?.length &&
                        data?.name
                      ) {
                        generateRandomNumber();
                        setOptError("");
                      } else {
                        setOptError(
                          "Please enter name,email and valid mobile number to send otp"
                        );
                      }
                    }}
                    >
                    
           <span className="text-success "> Verified</span> 
          
                  </Button>:
                  <>
<Button
variant="outline-primary"
className={`border ${verified ? `border-success` :`border-primary`} border-2 fw-bold mt-2 w-75`}
disabled={ timer}
onClick={() => {
if (
data?.mobile?.length == 10 &&
data?.email?.length &&
data?.name
) {
// setLoading(true)
generateRandomNumber();
setOptError("");
} else {
setOptError(
"Please enter name,email and valid mobile number to send otp"
);
}
}}
>
{
VisibleDiv ? "Resend OTP" : "Send OTP"
}
</Button>

{timer ? <CountdownTimer timer={180} setTimer={setTimer}/> :""}


</>

              }
        </div>
        {
          optError != "" &&
          <div className="invalid-formupload-feedback mt-1">
            {optError}
          </div>
        }
      </div>

      <div className="invalid-formupload-feedback mt-1">
        {errors?.otp}
      </div>
    </>
  
      <div className="d-flex gap-1">
    <div className="w-60" style={{ display: VisibleDiv ? "block" : "none" }}>
      <Form.Group className="form-group">
        <div className="form-control-wrap">
          <Form.Control
            type="number"
            name="otp"
            value={data?.otp?.length < 7 && data.otp}
            onChange={(e) => handleChange(e)}
            id="exampleFormControlInputText1"
            placeholder="Enter OTP"
            isInvalid={!!errors?.otp}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.otp}
          </Form.Control.Feedback>
        </div>
      </Form.Group>
    </div>
    <div className="w-40" style={{ display: VisibleDiv ? "block" : "none" }}>
      <Button
        variant="outline-primary"
        className="border border-primary border-2 fw-bold w-75"
        onClick={verifyOtp}
      >
        {verified ?  "Verified":"Verify OTP"}

      </Button>
    </div>
  </div>
  </>
  
  
      )
    }

export default FlagAndMobileOtp
