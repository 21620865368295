import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function YourAnswerCheckbox() {

  return (
    <div className='bg-white border rounded h-100'>
      <div className='bg-hilight text-secondary p-3 d-flex justify-content-between align-items-start'> <span>Your Answer</span><Button variant='primary' size="sm">Score Info 3/7</Button></div>
      <div className='p-3 answer-checkbox'>
        <Form.Check type="checkbox" id="checkbox-A-1" label="Since many in the country can  speak and understand English, the language in important for us." />

        <Form.Check className='right-ans' checked type="checkbox" id="checkbox-B-1" label="The literary important of English cannot be underestimated." />

        <Form.Check className='right-ans' checked type="checkbox" id="checkbox-C-1" label="Language plays an important role in research and Development " />

        <Form.Check className='wrong-ans' checked type="checkbox" id="checkbox-D-1" label="English has today become the language of the words. " />
      </div>
    </div>
  );
}
export default YourAnswerCheckbox;