import { useState } from "react";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2/src/sweetalert2";
import { Button, CloseButton } from "react-bootstrap";
import Icon from "../Icon/Icon";
// audio_video
function FileUploadAnnoucement({
  filetype,
  iconName,
  name,
  maxFiles,
  maxSize,
  minSize,
  errorText,
  onChange,
  noImage,
  accept,
  ImageData,
  setdata,
 
  ...props
}) {
  const [files, setFiles] = useState([]);

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles) => {
    console.log(acceptedFiles, "accepted files");
    const updatedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    console.log(updatedFiles[0].type,!updatedFiles[0].type =="image/gif",!updatedFiles[0].type=="image/jpeg","updatedFiles")

    if (filetype == "image") {
      console.log("aa#4 ", updatedFiles[0]);
      if (!updatedFiles[0].type.includes("image")) {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Please select Image",
        });
        return;
      }
      
      // else if (updatedFiles[0].type.startsWith("image/")) {
      //   console.log("updatedFiles[0].size", updatedFiles[0].size);
      //   if (updatedFiles[0].size < 1 * 1024 * 1024) {
      //     // 3MB limit

      //     Swal.fire({
      //       icon: "error",
      //       title: "Oops",
      //       text: "Image file is larger than 1MB",
      //     });
      //   } else {
      //     setFiles(updatedFiles);
      //     if (onChange) {
      //       onChange(updatedFiles);
      //     }
      //   }
      // } 
      
      else {
        setFiles(updatedFiles);
        if (onChange) {
          onChange(updatedFiles);
        }
      }
    }
  };
  // preview thumbs
  const thumbs =
    !noImage &&
    files.map((file) => (
      <div
        className="dz-preview dz-processing dz-image-preview dz-complete"
        key={file.name}
      >
        <div className="dz-image">
          <img src={file.preview} alt="preview" />
        </div>
      </div>
    ));


    const clickHandler=()=>{
      setFiles([])
      setdata((prev)=>({...prev,attachment:""}))

  }  

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles)}
        maxFiles={maxFiles}
        maxSize={maxSize}
        onDropRejected={() => alert(errorText)}
      >
        {({ getRootProps, getInputProps }) => (
<>
 {ImageData?.attachment  &&
  <CloseButton
                  className="upload-close-btn mb-4"
                  // value={item.url}
                  name="deletebanner"
                  onClick={
         
                    clickHandler
                  
                  
                  }
                />
  }
          <div {...getRootProps()} className="dropzone dz-clickable">
         
            <input {...getInputProps()} required />
            {(files.length === 0 || noImage) && (
              <div className="dz-message">
                <div className="dz-message-icon">
                  <Icon size="lg" name={iconName} />
                </div>
                <div className="dz-message-btn">
                  <Button size="md" variant="transparent">
                    Upload
                  </Button>
                  {/* <Button size="md" vaiant="transparent">Upload</Button> */}
                </div>
                {/* <span className="dz-message-text">Click to replace or drag and drop <small>{maxSize && 'Max ' + bytesToMegaBytes(maxSize) + ' MB'}</small></span> */}
                <small className="dz-message-text">
                  (Please select file for conversation)
                </small>
              </div>
            )}
            {files.length > 0 && files[0].type?.startsWith("image/")
              ? thumbs
              : files[0]?.name}
          </div>
          </>
        )}
      </Dropzone>
    </>
  );
}

export default FileUploadAnnoucement;
