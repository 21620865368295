import { useEffect, useState, useRef } from "react";
import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar, Table, OverlayTrigger, Popover } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon, Pureknob } from "../../../../components";
import { Colors } from "../../../../utilities/index";

function ResultSWTS() {
    const [activeProject, setactiveProject] = useState({
        size: 110,
        value: 5.5,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.primary,
        trackWidth: "0.15",
    });
    return (
        <>
            <div>
                <h5>AI Score</h5>

                <div className="d-flex gap-5 mt-3 mb-5">
                    <div className="shadow d-flex flex-column align-items-center p-3 w-30 rounded">
                        {/* pronunciation_accuracy */}
                        <div className="d-flex gap-3 justify-content-center align-items-center">
                        <Pureknob
                            data={activeProject}
                            key={activeProject.value}
                            className="nk-chart-project-active"
                        />
                        <div className="d-flex gap-3 text-secondary">
                            <div className="d-flex flex-column">
                                <span>Content :</span>
                                <span>Form :</span>
                                <span>Grammar :</span>
                                <span>Vocabulary :</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span>2/2</span>
                                <span>1/1</span>
                                <span>1/2</span>
                                <span>1.5/2</span>
                            </div>
                        </div>
                    </div>
                    <p className="text-secondary">Max Score : 7,  Your Score : <span className="text-danger fw-bold">5.5</span></p>
                    </div>
                    <div className="shadow d-flex gap-3 justify-content-center align-items-center p-3 w-70 rounded">
                        <p className="text-secondary">Reports from both
                            <OverlayTrigger overlay={
                                <Popover id="popover-positioned-disbled">
                                    <Popover.Header as="h3">Incorrect Noun Pluralization</Popover.Header>
                                    <Popover.Body>
                                        Incorrect Noun Pluralization
                                    </Popover.Body>
                                </Popover>
                            }>
                                <span className="text-danger text-decoration-underline mx-1" >
                                    Side
                                </span>
                            </OverlayTrigger>
                            of the Atlantic show that female students dominate university courses, and yet women still do not make it top, and a report on inequality in the UK said last week that girls had better educational results than boys at
                            <OverlayTrigger overlay={
                                <Popover id="popover-positioned-disbled">
                                    <Popover.Header as="h3">Grammar Error</Popover.Header>
                                    <Popover.Body>
                                        Grammar Error. Suggestion: 'went to' is probably not needed.
                                    </Popover.Body>
                                </Popover>
                            }>
                                <span className="text-danger text-decoration-underline mx-1" >
                                    went to
                                </span>
                            </OverlayTrigger>  university in greater numbers and achieved better degrees once they go there.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResultSWTS;