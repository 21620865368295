import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function QuestionMC() {

  return (
    <>
    <div className='p-3 text-middark d-flex flex-column align-items-start gap-3'>
      <b>Question : </b>
      <p> According to the writer, a student might repeat himself to _______</p>
    </div>
  </>
  );
}
export default QuestionMC;