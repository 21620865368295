import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function YourAnswerWFD() {
  return (
    <>
      <div className='bg-white border rounded h-100 d-flex flex-column justify-content-between'>
        <div className='d-flex flex-column justify-content-start'>
          <div className='bg-hilight text-secondary p-3 d-flex justify-content-between align-items-start'>
            <span>Your Answer</span><Button variant='primary' size="sm">Score Info 3/7</Button>
          </div>
          <p className='p-3 text-middark'>We are reading the significant challenges that we are experiencing these days.</p>
        </div>
        <div className='bg-hilight p-3 text-middark'><b>Incorrect Words: </b>researching, most, facing, in, our, society, today.</div>
      </div>
    </>
  );
}
export default YourAnswerWFD;