import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function MainQuestions() {

  return (
    <>
      <h5>Que No.1</h5>
      {/* used for Answer Short Question */}
      <p className='text-middark'>English is important not because several people know it, although it is a factor to be remembered. It is not important because it is the language of Milton and Shakespeare, although that also has to be considered. English is important because it is the major window in the modern world. And we dare not close that window. If we close it, we imperil our future. Be it industrialization, scientific development, and technology, every door of modern knowledge will be closed if we do not have one or more foreign languages. We can have French, German, or Russian if you like, but obviously, it is infinitely simpler for us to deal with a language that we know than to shift over to French, German, Russian, or Spanish which will be a tremendous job. Every competent scientist today has to know two or three non-native languages. In the present age of industrialization, mechanization, scientific progress, and research, we cannot progress without knowledge of English or, for that matter, French, German or Russian.</p>
      <p className='fw-bold text-secondary'>Whose job is it to treat people that are ill or have an injury at a hospital?</p>
    </>
  );
}
export default MainQuestions;