import { Link } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { MediaGroup, Media, MediaText, Image, Icon, CustomDropdownToggle, CustomDropdownMenu, LinkList, LinkListItem } from "../../components";
import { toInitials } from "../../utilities";

// user table column
export const StudentColumns = [

    {
        name: "Student Name",
        grow: "3",
        selector: (row) => row.name,
        cell: (row) => (
            <MediaGroup>

                <Media size="md" shape="circle" variant={row.theme && row.theme}>
                    {row.avatar ?
                        <Image src={row.avatar} staticImage /> :
                        <span className="smaller fw-medium">{toInitials(row.name)}</span>
                    }
                </Media>
                <MediaText>
                    {/* <Link to={`/student-management/student-detail/${row.id}`} className="title">{row.name}</Link> */}
                    {row.name}
                </MediaText>
            </MediaGroup>
        ),
        sortable: true,
    },
    {
        name: "Product",
        selector: (row) => row.product,
        cell: (row) => (
            <span className="text-middark">{row.product}</span>
        ),
        sortable: true,
    },
    {
        name: "Username",
        grow: "3",
        selector: (row) => row.username,
        cell: (row) => (
            <span className="text-middark">{row.username}</span>
        ),
        sortable: true,
    },
    {
        name: "Student Type",
        selector: (row) => row.studenttype,
        cell: (row) => (
            <span className="text-middark">{row.studenttype}</span>
        ),
        sortable: true,
    },
    {
        name: "Assigned",
        selector: (row) => row.assigned,
        cell: (row) => (
            <span className="text-middark">{row.assigned}</span>
        ),
        sortable: true,
    },
    {
        name: "Attempt",
        selector: (row) => row.attempt,
        cell: (row) => (
            <span className="text-middark">{row.attempt}</span>
        ),
        sortable: true,
    },
    {
        name: "Score",
        selector: (row) => row.score,
        cell: (row) => (
            <span className="text-middark">{row.score}</span>
        ),
        sortable: true,
    },
    {
        name: "status",
        selector: (row) => row.status,
        cell: (row) => (
            <span className={`badge text-bg-${row.status === "Active" ? "success-soft"
                    : row.status === "Inactive" ? "danger-soft"
                        : "primary-soft"}`
            }>
                {row.status ? row.status : 'General'}
            </span>
        ),
        sortable: true,
    },
    {
        name: "action",
        cell: (row) => (
            <div className="text-end w-100">
                <LinkList className="link-list-hover-bg-primary link-list-md d-flex">
                    <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
                        <Icon name="edit"></Icon>
                    </LinkListItem>
                    <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
                        <Icon name="trash"></Icon>
                    </LinkListItem>
                </LinkList>
            </div>
        ),
        sortable: false,
    },

];

// users data
const StudentData = [
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    }, {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    }, {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Inactive',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    }, {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Inactive',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: 'uid01',
        name: 'Lois A',
        product: 'PTE',
        username: 'kokenaw238@gmail.com',
        studenttype: 'Bulk Test',
        assigned: '02',
        attempt: '02',
        score: '160',
        email: 'florenza@gmail.com',
        website: 'www.softnio.com',
        avatar: '/images/avatar/a.jpg',
        description: `<p>I code and design websites worldwide. Mauris varius tellus vitae tristique sagittis. Sed aliquet, est nec auctor aliquet, orci ex vestibulum ex, non pharetra lacus erat ac nulla.</p><p>Sed vulputate, ligula eget mollis auctor, lectus elit feugiat urna, eget euismod turpis lectus sed ex. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc ut velit finibus, scelerisque sapien vitae, pharetra est. Nunc accumsan ligula vehicula scelerisque vulputate. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deleniti, dolore?</p>`,
        role: 'Owner & Founder',
        plan: 'Basic',
        billing: 'Auto Debit',
        joining: '2022/04/25',
        status: 'Active',
        followers: '2574',
        following: '78',
        address: 'California, United States',
        company: 'Softnio',
        designation: 'Frontend Developer',
        skills: ['Photoshop', 'illustrator', 'HTML', 'CSS', 'Javascript', 'React', 'Vue', 'Angular', 'Python'],
        social: [
            {
                site: 'github-circle',
                variant: 'text-bg-dark',
                link: '/softnio'
            },
            {
                site: 'dribbble',
                variant: 'text-bg-danger',
                link: '/softnio'
            },
            {
                site: 'twitter',
                variant: 'text-bg-info',
                link: '/softnio'
            },
            {
                site: 'linkedin',
                variant: 'text-bg-pink',
                link: '/softnio'
            }
        ],
        activity: [
            {
                type: 'media',
                time: '2:12 PM',
                title: 'Added 3 New Images',
                images: [
                    {
                        src: '/images/product/a.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/b.jpg',
                        alt: 'product image'
                    },
                    {
                        src: '/images/product/c.jpg',
                        alt: 'product image'
                    }
                ]
            },
            {
                type: 'regular',
                time: '4:23 PM',
                title: 'Invitation for creative designs pattern',
            },
            {
                type: 'file',
                time: '10:30 PM',
                title: 'Task report - uploaded weekly reports',
                files: [
                    {
                        type: 'pdf',
                        title: 'Modern Designs Pattern',
                        size: '1.6 mb'
                    },
                    {
                        type: 'doc',
                        title: 'cPanel Upload Guidelines',
                        size: '18 kb'
                    },
                    {
                        type: 'code',
                        title: 'Weekly Finance Reports',
                        size: '10 mb'
                    },
                ]
            },
            {
                type: 'alert',
                time: '5:05 PM',
                title: 'You have received a new order',
                alerts: [
                    {
                        icon: 'file-code',
                        title: 'Business Template - UI/UX design',
                        description: 'Shared information with your team to understand and contribute to your project.',
                        buttons: [
                            {
                                variant: 'primary',
                                icon: 'download',
                                text: 'Download'
                            }
                        ]
                    },
                ]
            }
        ]
    },
];

export default StudentData;
