import { Card, Form, Row, Col, Button, ButtonGroup, Tab, Tabs, Nav, ProgressBar, Table, OverlayTrigger, Popover } from "react-bootstrap";
import Layout from "../../../../layout/default";
import { Block, Icon } from "../../../../components";

function TableResult() {

    return (
        <>
            <div>
                <h5>AI Score</h5>
                <Table responsive bordered className="practice-table">
                    <thead>
                        <tr>
                            <th className="py-3 bg-exambottom">Component</th>
                            <th className="py-3 bg-exambottom">Score</th>
                            <th className="py-3 bg-exambottom">Suggestion</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Content</td>
                            <td>2/2</td>
                            <td>Great!</td>
                        </tr>
                        <tr>
                            <td>Form</td>
                            <td>1/1</td>
                            <td>Great form!</td>
                        </tr>
                        <tr>
                            <td>Grammar</td>
                            <td>1/2</td>
                            <td>Click on the colored texts to check grammar error details.</td>
                        </tr>
                        <tr>
                            <td>Vocabulary</td>
                            <td>1.5/2</td>
                            <td>Using words from the original text is allowed in SWT. But also pay attention to spelling and grammar while copying.</td>
                        </tr>
                        <tr>
                            <td colSpan="3" className="py-3 bg-exambottom">Max Score : 7,  Your Score : <span className="text-danger fw-bold">5.5</span></td>
                        </tr>
                    </tbody>
                </Table>
                <p className="text-secondary">Reports from both
                    <OverlayTrigger overlay={
                        <Popover id="popover-positioned-disbled">
                            <Popover.Header as="h3">Incorrect Noun Pluralization</Popover.Header>
                            <Popover.Body>
                                Incorrect Noun Pluralization
                            </Popover.Body>
                        </Popover>
                    }>
                        <span className="text-danger text-decoration-underline mx-1" >
                            Side
                        </span>
                    </OverlayTrigger>
                    of the Atlantic show that female students dominate university courses, and yet women still do not make it top, and a report on inequality in the UK said last week that girls had better educational results than boys at
                    <OverlayTrigger overlay={
                        <Popover id="popover-positioned-disbled">
                            <Popover.Header as="h3">Grammar Error</Popover.Header>
                            <Popover.Body>
                                Grammar Error. Suggestion: 'went to' is probably not needed.
                            </Popover.Body>
                        </Popover>
                    }>
                        <span className="text-danger text-decoration-underline mx-1" >
                            went to
                        </span>
                    </OverlayTrigger>  university in greater numbers and achieved better degrees once they go there.</p>
            </div>
        </>
    )
}

export default TableResult;