import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../../components';
import StudentResultContext from '../../../../context/StudentResultContext/StudentResultContext';
function ResultFIBRWNew({fib_wrQuest,ansItem}) {

  // const {studentResult}=React.useContext(StudentResultContext)
  // let fib_wrQuest=studentResult?.questions?.filter((item)=>
  // item?.module_name == "fib_wr")
  
  console.log('fib_wrQu23est32 :>> ',fib_wrQuest);
  
  // let fib_wrAns=studentResult?.answers?.filter((item)=>
  // item?.submodule == "fib_wr")
  
  console.log('fib_wrAnsansItemansItem?.answer :>> ',ansItem?.answer)
  console.log('fib_wrAnsansItemansItem?.answer :>> ',ansItem?.answer?.split(",").filter(Boolean))
  // const res=fib_wrAns?.map((ansitem,index)=>{
  //  return ansitem.answer?.split(",").map((ansEle) => {

  //    return ansEle
  //  }
  // )
  // })

  const res= ansItem?.answer?.split(",").map((ansEle) => {
 
      return ansEle
    }
   )


   const jsxResult = fib_wrQuest?.question_content?.split(" ")?.map((ele, index) => {
    const matchedOption = fib_wrQuest?.question_options?.find(item => item.options === ele);

    console.log('matchedOption :>> ', matchedOption);
    
    return matchedOption ? (
      <span key={index} className='text-danger fw-bold'>{ele} <span  key={index} className='text-success fw-bold'>{matchedOption?.answer}</span> </span>
    ) : (
      <span key={index}>{ele} </span>
    );
  });
   

  

  

  // const jsxResutl=fib_wrQuest?.question_content?.split(" ")?.map((ele)=>{
  //   let a=ansItem?.answer?.split(",")?.length-1
    
  //   const ans= ansItem?.answer?.split(",")?.find((item,index)=>item)
    
  //   console.log('fadsf@ans :>> ', ans);
  //  if (ans) a--;
  //  console.log('aaaaa :>> ', a);
  //   return fib_wrQuest?.answer?.split(",")?.includes(ele) ? <span className='text-success fw-bold'>(Answer:{ele}){ansItem?.answer?.split(",")?.[a]} </span> : <span>{ele} </span>
   
  // })


  
  
  const answer = "bleak,equitable,Cairo";

// const jsxResutl = fib_wrQuest?.question_content?.split(" ")?.map((word, index) => {
//     const answers = ansItem?.answer?.split(",")?.filter(Boolean); // Split answers by comma
//     console.log('answers :>> ', answers,answers.length);
//     const ansIndex = index % answers.length; // Get the correct index for the answer
//     console.log('ansIndex :>> ', ansIndex,index % answers.length);

//     const ans = answers[ansIndex]; // Get the answer corresponding to the current word
//     console.log('nswers[ansIndex] :>> ',answers, ansIndex);
//     const isAnswer = fib_wrQuest?.answer?.split(",")?.includes(word); // Check if the word is an answer

//     console.log('11ans11 :>> ', ans);
//     return isAnswer ? (
//         <span className='text-success fw-bold'>(Answer:{word}){ans} </span>
//     ) : (
//         <span>{word} </span>
//     );
// });



// const jsxResutl = fib_wrQuest?.question_content?.split(" ")?.map((word, index) => {
//     const answers = ansItem?.answer?.split(",")?.filter(Boolean); // Split answers by comma
//     console.log('answers :>> ', answers,answers.length);
//     const ansIndex = index % answers.length; // Get the correct index for the answer
//     console.log('ansIndex :>> ', ansIndex,index % answers.length);

//     const ans = answers[ansIndex]; // Get the answer corresponding to the current word
//     console.log('nswers[ansIndex] :>> ',answers, ansIndex);
//     const isAnswer = fib_wrQuest?.answer?.split(",")?.includes(word)
// ; // Check if the word is an answer

//     console.log('11ans11 :>> ', ans);
//     return isAnswer ? (
//         <span className='text-success fw-bold'>(Answer:{word}){ans} </span>
//     ) : (
//         <span>{word} </span>
//     );
// })


// const jsxResutl = fib_wrQuest?.question_content?.split(" ")?.map((word, index) => {
//   const answers = ansItem?.answer?.split(",")?.filter(Boolean); // Split answers by comma and filter out any empty strings
//   const ansIndex = answers ? index % answers.length : 0; // Get the correct index for the answer or default to 0 if no answers available
//   const ans = answers ? answers[ansIndex] : ''; // Get the answer corresponding to the current word or default to an empty string if no answers available
//   const isAnswer = fib_wrQuest?.answer?.split(",")?.includes(word); // Check if the word is an answer

//   return (
//       <span key={index}>
//           {isAnswer ? (
//               <span className='text-success fw-bold'>(Answer:{word}){ans} </span>
//           ) : (
//               <span>{word} </span>
//           )}
//       </span>
//   );
// });

const questionWords = fib_wrQuest?.question_content?.split(" ") ?? [];
const answers = ansItem?.answer?.split(",")?.filter(Boolean) ?? [];
let ansIndex = 0;
let ans;

const jsxResutl = questionWords.map((word, index) => {
    const isAnswer = fib_wrQuest?.answer?.split(",")?.includes(word);
    console.log('isAnswer :>> ', isAnswer,ansIndex,answers.length);

    if (isAnswer) {
      console.log('answe22r :>> ', answers,ansIndex);
         ans = answers[ansIndex];
        ansIndex++;
     } 

    return isAnswer ?
     (
      <span className='text-success fw-bold'>(Answer:{word}){ans} </span>
  ) : (
      <span>{word} </span>
  );
});


// const jsxResutl = fib_wrQuest?.question_content?.split(" ")?.map((word, index) => {
//     const answers = ansItem?.answer?.split(",")?.filter(Boolean); // Split answers by comma
//     console.log('answers :>> ', answers,answers.length);
//     const ansIndex = index % answers.length; // Get the correct index for the answer
//     console.log('ansIndex :>> ', ansIndex,index % answers.length);

//     const ans = answers[ansIndex]; // Get the answer corresponding to the current word
//     console.log('nswers[ansIndex] :>> ',answers, ansIndex);
//     const isAnswer = fib_wrQuest?.answer?.split(",")?.includes(word)
// ; // Check if the word is an answer

//     console.log('11ans11 :>> ', ans);
//     return isAnswer ? (
//         <span className='text-success fw-bold'>(Answer:{word}){ans} </span>
//     ) : (
//         <span>{word} </span>
//     );
// });







// const [ans, setAns] = React.useState();

// React.useEffect(() => {
//     if (Array.isArray(fib_wrQuest?.question_options?.[0]?.options)) {
//         const matchedOption = fib_wrQuest?.question_options?.[0]?.options?.find((myele) => ansItem?.answer?.split(",")?.includes(myele) &&  ansItem?.qst_id==fib_wrQuest?._id);
//         setAns(matchedOption);
//     }
// }, [fib_wrQuest?.question_options, ansItem]);

// console.log('anfsds :>> ', ans);

// const jsxResutl = fib_wrQuest?.question_content?.split(" ")?.map((ele) => {
//     return fib_wrQuest?.answer?.split(",")?.includes(ele) ? (
//         <span key={ele} className='text-success fw-bold'>(Answer: {ele}){ans}</span>
//     ) : (
//         <span key={ele}>{ele}</span>
//     );
// });


//   console.log('afsdfres :>> ', res);


  // let fib_wrQuestAll=studentResult?.filter((item)=>
  // item?.module_name == "fib_wr" && item?.submodule == "fib_wr")
  



  return (
    <>
    <div className='p-3 text-middark'>
      Learning to write well in college means learning (or re-learning) how to write clearly and plainly. Now that doesn't mean that plainness is the only good style, or that you should become a
      <div className='res-ans text-success'><span><img src='/images/right-ans.svg'></img> slave</span> (Answer : slave)</div>
      to spare, unadorned writing. Formality and ornateness have their place, and in
      <div className='res-ans text-danger'><span><img src='/images/wrong-ans.svg'></img> helping</span> (Answer : competent)</div>
      hands complexity can take us on a dizzying, breathtaking journey. But most students, most of the time should
      <div className='res-ans text-danger'><span><img src='/images/wrong-ans.svg'></img> reject</span> (Answer : endeavor)</div>
      to be sensibly simple to develop a baseline style of short words, active verbs and relatively simple sentence
      <div className='res-ans text-success'><span><img src='/images/right-ans.svg'></img> carrying</span> (Answer : carrying)</div>
      clear actions or identities. It's faster, it makes arguments easier to follow, it increases the chances a busy reader will bother to pay attention, and it lets you
      <div className='res-ans text-success'><span><img src='/images/right-ans.svg'></img> center</span> (Answer : center)</div>
      more attention on your moments of rhetorical flourish which I do not advise abandoning altogether.
    </div>


    {/* <div className='p-3 text-middark'>
   {
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")
    })
   }
  </div> */}

{/* map inside map */}
      {/* <div className='p-3 text-middark'>
   {
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((item)=>{
        return item + " "
      })
    })
   }
  </div>
   */}



  
      {/* <div className='p-3 text-middark'>
      {
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((ele)=>{
        return item?.answer?.split(",")?.includes(ele) ? <span className='text-success fw-bold'>(Answer:{ele}) </span> : <span>{ele} </span>
      })
    })
   }
  </div> */}
     <div className='p-3 text-middark'>
     {
jsxResutl
}
  </div>





{/* {fib_wrQuest?.[0]?.question_content?.split(" ")?.map((ele) => {return fib_wrQuest?.[0]?.answer?.split(",")?.includes(ele) ? <span className='border-bottom border-danger'>{ele} </span> : <span>{ele} </span>} )} */}




{/* 

{arrayOfArrays.map((array, index) => (
        <ul key={index}>
          {array.map((item, subIndex) => (
            <li key={subIndex}>{item}</li>
          ))}
        </ul>
      ))}
   */}
  {/* <div className='p-3 text-middark'>
      {
        fib_wrAns?.map((ansitem,index)=>(
          <ul key={index}>
  

       {ansitem.answer?.split(",").map((ansEle, idx) => (
       <span key={idx}>

     {
      
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((ele)=>{
       
        return item?.answer?.split(",")?.includes(ele) ? <span className='text-success'>({ansEle})(Answer:{ele}) </span> : <span>{ele} </span>
      })
    })}
    </span>))}
    </ul>))

    
   }




  </div> */}
 

{/* <div className='p-3 text-middark'>
      {
        fib_wrAns?.map((ansitem,index)=>(
          <ul key={index}>
  

       {ansitem.answer?.split(",").map((ansEle, idx) => (
       <span key={idx}>

     {
      
     fib_wrQuest?.map((item)=>{
      return item?.question_content?.split(" ")?.map((ele)=>{
       
        return   item?.answer?.split(",")?.includes(ele) ? <span className='text-success'>({res?.[0]?.[idx]})(Answer:{ele}) </span> : <span>{ele} </span>
      })
    })}
    </span>))}
    </ul>))

    
   }



  </div> */}

  

      {/* {console.log("ext-middar",fib_wrQuest?.length>0 && fib_wrQuest[0].question_content?.split(" "))} */}
  {/* <div className='p-3 text-middark'>
   {
    fib_wrQuest?.length>0 && fib_wrQuest[0].question_content?.split(" ")?.map((item)=>{
      return item + " "
    })
   }
  </div> */}

  </>
  );
}
export default ResultFIBRWNew;