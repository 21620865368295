
import React from 'react';

const DraggableComponent = (props) => {
  const handleDragStart = (e) => {
    e.dataTransfer.setData("Text", props.id);
  };

  return (
    <span
      className="draggable bg-white p-1"
      id={props.id}
      draggable="true"
      onDragStart={handleDragStart}
    >
      {props.text}
    </span>
  );
};

export default DraggableComponent;
