import React, { useState, useContext, useRef } from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Nav,
  ProgressBar,
  Badge,
  Offcanvas,
  Table,
  OverlayTrigger,
  Popover,
  InputGroup,
  Pagination,
} from "react-bootstrap";
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../layout/default";
import { Block, Icon } from "../../../components";
import Select from "../../../components/Form/Select";
import PracticePteComments from "./PracticePteComments";
import NoUiSlider from "../../../components/Form/NoUiSlider";
import { KanbanBasic } from "../../../components/Kanban/Kanban";
import PracticeTopHeading from "./PracticeComponents/PracticeTopHeading";
import PracticeTitleTime from "./PracticeComponents/PracticeTitleTime";
import PracticeParagraph from "./PracticeComponents/PracticeParagraph";
import PracticeMicrophone from "./PracticeComponents/PracticeMicrophone";
import PracticeAudio from "./PracticeComponents/PracticeAudio";
import PracticePlayingAudio from "./PracticeComponents/PracticePlayingAudio";
import PracticeDescribeImage from "./PracticeComponents/PracticeDescribeImage";
import PracticeTextArea from "./PracticeComponents/PracticeTextArea";
import PracticeFIBRW from "./PracticeComponents/PracticeFIBRW";
import { ArrayAll } from "./allModuleArray";
import { useEffect } from "react";
import { getQuestion_by_module_name } from "./PracticeTestFunctions/index";
import AdminApi from "../../../api/admin";
import PracticePteReadingFIB from "./ReadingTest/PracticePteReadingFIB";
import PracticePteReadingMultiChoiceRadio from "./ReadingTest/PracticePteReadingMultiChoiceRadio";
import PracticePteListeningFIB from "./ListeningTest/PracticePteListeningFIB";
import PracticePteListeningHIWS from "./ListeningTest/PracticePteListeningHIWS";
import { S3upload } from "../../exam/PTE/S3upload";
import ModalContext from "../../../context/ModalContext";
import Context from "../../../context";
import InputCheckbox from "../../exam/PTE/inputComponents/inputCheckbox";
import Swal from "sweetalert2";
import AudioUI from "../../exam/PTE/Istructions/ExamComponents/AudioUI";
import ReadingReOrder from "../../exam/PTE/ReadingTest/ReadingReOrder";
import ReadingFillInBlanks from "../../exam/PTE/ReadingTest/ReadingFillInBlanks";
import ReadWriteFillinBlanks from "../../exam/PTE/ReadingTest/ReadWriteFillinBlanks";
import TableResult from "./PracticeComponents/TableResult";
import ResultSWTS from "./PracticeComponents/ResultSWTS";
import PracticeResult from "./PracticeComponents/PracticeResult";
import DragDropBoth from "../../kanban/DragDropBoth";
import PracticeTitleTimeForAudio from "./PracticeComponents/PracticeTitleTimeForAudio";
import AuthApi from "../../../api/auth";
import SubmitButton from "../../questions/PTE/SubmitButton";
import ReactPaginate from "react-paginate";
function PracticePte() {
  let { id } = useParams();
  let api = new AdminApi();
  let path = window.location.pathname;
  var module_name = path.split("/")[3] ? path.split("/")[3] : ""; //read_alouds,swts etc ..
  let navigate = useNavigate();
  const [openQuestionTop, setopenQuestionTop] = useState(true);
  const [data, setData] = useState({
    index_for_question_destructure: 0,
    loadedQuestionObj: {},
    questionsBy_module_name: [],
    questionsBy_module_name_for_filter: [],
    eventKey: "AllQuestions",
    selected_ques_Obj: {},
  });

  console.log("DATADATA ->F ", data)

  const [filterObj, setfilterObj] = useState({ isExplantion: null, isPractice: "all" });
  const [isSubmitted, setisSubmitted] = useState(false)
  const [loader, setloader] = useState(false)
  const scrollTop = useRef(null)
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const run = () => {
    console.log("run", run);
  };
  console.log("filterObj", filterObj);
  let {
    index_for_question_destructure,
    loadedQuestionObj,
    questionsBy_module_name,
    eventKey,
    questionsBy_module_name_for_filter,
    selected_ques_Obj,
    readAloudAnswer,
  } = data;

  console.log("eventKey",eventKey);
  
  var { question_transcript, question_explanation, sample_answer, answer, answer_content_audio } =
    selected_ques_Obj;
  const { userData, setUserData, localHost, incorreCtDomain } =
    useContext(Context);
  let { setAnswerJson, answerJson, timeForPractice, setTimeForPractice } =
    useContext(ModalContext);

  let for_read_alouds_page =
    path.includes("/practice/pte/read_alouds") ||
    path.includes("/practice/pte/repeat_sentences") ||
    path.includes("/practice/pte/describe_images") ||
    path.includes("/practice/pte/retell_lectures") ||
    path.includes("/practice/pte/answer_questions");

  const [currentPage, setCurrentPage] = useState(1);
  const [paginate, setPaginate] = useState({ offset: 0, limit: 20, currentPage: 1 })
  const itemsPerPage = 20;

  const [navLinks, setNavLinks] = useState([0]);
  console.log("navLinks", navLinks);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  console.log("PAGINATE", paginate);
  const handlePageChange = (event) => {
    console.log("PAGEEVENT", event.selected);
    if (event.target?.name == "goToPageNumber") {
      console.log("goToPageNumber", event.target.value);
      console.log("EVENT", event.target.value);
      event.target.value == "" ? setPaginate({ offset: 0, limit: 20, currentPage: event.target.value }) : setPaginate({ offset: (Number(event.target.value) - 1) * 20, limit: 20, currentPage: event.target.value })
    }
    else {
      setPaginate({ offset: event.selected * 20, limit: 20, currentPage: event.selected + 1 })
    }

    if (event.target?.name != "goToPageNumber" || event.target.value > 0){
      scrollTop.current.scrollIntoView({ behavior: 'smooth' });
    }
    
  };
  console.log("DATATATA", data);
  async function fetchData() {
    try {
      let find_loadedQuestionObj = ArrayAll.find(
        (item) => item.module_name === module_name
      );
      setData((prev) => ({
        ...prev,
        loadedQuestionObj: find_loadedQuestionObj,
      }));
      const response = await getQuestion_by_module_name(
        module_name,
        JSON.parse(userData)?._id,
        paginate,
        filterObj
      );
      if (response && response?.question?.length) {
        console.log("INSIDEFETCH");
        
        setAnswerJson({});
        let arr = response.question;
        const currentItems = arr.slice(indexOfFirstItem, indexOfLastItem);
        console.log("ARR",arr);
        console.log("currentItems",currentItems);
        
        
        setData((prev) => ({
          ...prev,
          questionsBy_module_name: currentItems,
          questionsBy_module_name_for_filter: arr,
          totalDocuments: response?.length,
          isPracticedDoneCount: response?.isPracticedDoneCount,
          // selected_ques_Obj: currentItems ? currentItems[0] : {},
        }));
      } else {
        console.log("INSIDEFETCH");
        setData((prev) => ({
          ...prev,
          questionsBy_module_name: [],
          questionsBy_module_name_for_filter: [],
        }));
        setopenQuestionTop(true);
      }
    } catch (error) {
      console.error("Error", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [paginate.offset, filterObj]);

  useEffect(() => {
    if(filterObj?.title?.length > 0){
      setfilterObj({...filterObj, title:""})
    }
  },[openQuestionTop])

  useEffect(() => {
    if (questionsBy_module_name.length > 0 && !id) {
      setfilterObj({ isExplantion: null, isPractice: "all" });
      if (questionsBy_module_name[index_for_question_destructure]) {
        navigate(
          `/practice/pte/${questionsBy_module_name[index_for_question_destructure].module_name}/${questionsBy_module_name[index_for_question_destructure]?.id}`
        );
        setopenQuestionTop(true);
      } else {
        setopenQuestionTop(true);
      }
    } else {
      //  if(id){
      //   setData({
      //     ...data,
      //     index_for_question_destructure:  questionsBy_module_name_for_filter.length ? questionsBy_module_name_for_filter.findIndex((item)=>item.id==id):0,
      //   });
      //  }
      // questionsBy_module_name_for_filter.length && questionsBy_module_name_for_filter.find((item)=>item.id==id)
    }
    
  }, [questionsBy_module_name]);

  useEffect(() => {
    if (id) {
      setAnswerJson({});
      getQuestionBy_id(id);
    } else {
      fetchData();
    }
    setTimeForPractice({
      ...timeForPractice,
      prepare: { status: false, time: "00:00" },
      original: { status: false, time: "00:00" },
      isRunning: false,
      startRecord: false,
    });
  }, [id]);

  //tochan
  useEffect(() => {
    setSwitchChecked(false)
    setisSubmitted(false)
  }, [selected_ques_Obj])

  useEffect(() => {
    // console.log("objectKeys",Object.keys(filterObj).length);
    // setPaginate({offset:0, limit:20, currentPage:1})
    if (filterObj?.isExplantion != null || filterObj?.isPractice != "all" || Object.keys(filterObj).length > 2) {
      const toClick = document?.getElementsByClassName("toClick")[0]?.children[0]
      console.log("toClick", toClick);
      if (toClick) {
        toClick.click();
      }

    }

    // if(Object.keys(filterObj).length > 1){
    // document.getElementsByClassName("toClick selected")[0].classList.remove("selected")
    // document.getElementsByClassName("toClick selected")[0].classList.add("selected")
    // }
  }, [filterObj])

  const getQuestionBy_id = async (param) => {
    console.log("vidddddd", id)
    await api
      .get_qst_byID(param)
      .then((res) => {
        setRedoCount((prevCount) => prevCount + 1);
        setTimeForPractice({
          ...timeForPractice,
          prepare: { status: false, time: "00:00" },
          original: { status: false, time: "00:00" },
          isRunning: false,
          startRecord: false,
        });
        setData((prev) => ({
          ...prev,
          selected_ques_Obj: res?.data ? res?.data : {},
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [redoCount, setRedoCount] = useState(0);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [searchedText, setSearchedTex] = useState("");
  const handleSwitchChange = () => {
    setSwitchChecked(!switchChecked);
  };
  console.log("questionsBy_module_name", questionsBy_module_name);
  const filter_by_Question = async (param, currentMonth, curentWeek) => {
    // setfilterObj({ isExplantion: false });
    if (param === "all") {
      // setfilterObj({isExplantion:false})
      try {
        const response = await getQuestion_by_module_name(module_name, paginate,
          filterObj);
        searchQuestion2(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          response.question
        );
        setData((prev) => ({
          ...prev,
          eventKey: "AllQuestions",
          // questionsBy_module_name:   response.question.length > 0 && response.question?.slice(
          //   indexOfFirstItem,
          //   indexOfLastItem
          // ),
          // questionsBy_module_name_for_filter: response.question,
        }));
      } catch (error) {
        console.error("Error", error);
      }
    }
    if (param === "week") {
      try {
        var lastSunday = new Date();
        lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
        searchQuestion2(null, null, null, null, null, null, null, param);
        setfilterObj({ ...filterObj, week: param });
        setData((prev) => ({
          ...prev,
          eventKey: "week",
          // questionsBy_module_name: filter,
          // questionsBy_module_name_for_filter: filter,
        }));
      } catch (error) {
        console.error("Error", error);
      }
    }
    if (currentMonth) {
      setfilterObj({ ...filterObj, week: null, currentMonth: param });
      searchQuestion2(null, null, null, null, null, null, null, null, param);
      setData((prev) => ({
        ...prev,
        eventKey: currentMonth,
        // questionsBy_module_name: filter,
        // questionsBy_module_name_for_filter: filter,
      }));
    }
  };
  const searchQuestion2 = async (
    questionType,
    isPractice,
    image_type,
    title,
    isBookMark,
    isExplantion,
    explanatory_video,
    week,
    month,
    inputSearch
  ) => {
    questionType =
      questionType != null && questionType != undefined
        ? questionType
        : filterObj.type_question;
    var islength =
      questionType == "long" || questionType == "short"
        ? questionType
        : questionType == null
          ? filterObj?.islength
          : null;
    isPractice = isPractice != null ? isPractice : filterObj?.isPractice;
    isExplantion =
      isExplantion != null ? isExplantion : filterObj?.isExplantion;
    explanatory_video =
      explanatory_video != null
        ? explanatory_video
        : filterObj?.explanatory_video;
    image_type = image_type != null ? image_type : filterObj?.image_type;
    isBookMark =
      isBookMark != null ? String(isBookMark) : String(filterObj?.isBookMark);
    title = title != null ? title : filterObj?.title;
    inputSearch = inputSearch != null ? inputSearch : filterObj?.inputSearch;
    week =
      typeof week == "object" || week == null || month
        ? null
        : typeof week == "string"
          ? week
          : filterObj?.week;
    month = month != null ? month : (week != null || typeof week == "object") ? null : filterObj?.currentMonth;

    // const response = await getQuestion_by_module_name(
    //   module_name,
    //   JSON.parse(userData)?._id,
    //   paginate,
    //     filterObj
    // );
    console.log("weeweekweekweekweekweekk", questionType)
    console.log("islength", islength)
    console.log("isPractice", isPractice)
    console.log("isExplantion", isExplantion)
    console.log("explanatory_video", explanatory_video)
    console.log("image_type", image_type)
    console.log("isBookMark", isBookMark)
    console.log("title", title)
    console.log("inputSearch", inputSearch)
    console.log("week", week)
    console.log("monthmonthmonth", (week != null && typeof week == "object"))
    // const filteredData = (
    //   (await response?.question?.length) > 0 &&
    //   (questionType == "question_num" && questionType != undefined
    //     ? response?.question
    //     : response?.question.reverse())
    // ).filter((item) => {
    //   var lastSunday = new Date();
    //   lastSunday.setDate(lastSunday.getDate() - lastSunday.getDay());
    //   var d2 = new Date(item.isPredUpdate).getTime();

    //   const abbreviatedMonth = new Date(item.isPredUpdate).toLocaleString(
    //     "default",
    //     { month: "short" }
    //   );
    //   return (
    //     (!month || (item.isPrediction && abbreviatedMonth === month)) &&
    //     (!week || (item.isPrediction && lastSunday.getTime() < d2)) &&
    //     (!islength || islength === String(item?.islength)) &&
    //     (!isPractice || String(isPractice) === String(item?.isPractice)) &&
    //     (!isExplantion || String(isExplantion) === String(item?.isExplained)) &&
    //     (!explanatory_video || item?.explanatory_video) &&
    //     (!image_type || String(image_type) === String(item?.image_type)) &&
    //     (isBookMark == "undefined" ||
    //       !isBookMark ||
    //       String(isBookMark) ==
    //         String(
    //           item?.isBookmark?.bookmark ? item?.isBookmark?.bookmark : false
    //         )) &&
    //     (!title ||
    //       (typeof item.tags === "string" && item.tags.includes(title)) ||
    //       (item.question_content &&
    //         typeof item.question_content === "string" &&
    //         item.question_content.includes(title)) ||
    //       (item.id && item.id.toString().includes(title.toString()))) &&
    //     (!inputSearch ||
    //       (item.tags &&
    //         typeof item.tags === "string" &&
    //         item.tags.includes(inputSearch)) ||
    //       (item.question_content &&
    //         typeof item.question_content === "string" &&
    //         item.question_content.includes(inputSearch)) ||
    //       (item.id && item.id.toString().includes(inputSearch.toString())))
    //   );
    // });
    // console.log("filteredData", filteredData);
    // setData((prev) => ({
    //   ...prev,
    //   questionsBy_module_name:
    //     filteredData.length > 0 &&
    //     filteredData?.slice(indexOfFirstItem, indexOfLastItem),
    //   questionsBy_module_name_for_filter: filteredData,
    // }));
  };

  const nextQuestion = () => {
    setRedoCount((prevCount) => prevCount + 1);
    setTimeForPractice({
      ...timeForPractice,
      prepare: { status: false, time: "00:00" },
      original: { status: false, time: "00:00" },
      isRunning: false,
      startRecord: false,
    });

    console.log("questionsBy_module_name", questionsBy_module_name);
    if (questionsBy_module_name.length > 0) {
      // if (questionsBy_module_name[index_for_question_destructure + 1]) {
      navigate(
        `/practice/pte/${questionsBy_module_name[index_for_question_destructure + 1]
          .module_name
        }/${questionsBy_module_name[index_for_question_destructure + 1].id}`
      );
      setData({
        ...data,
        index_for_question_destructure: index_for_question_destructure + 1,
      });
      // }
    }
  };
  const PreviousQuestion = () => {
    setRedoCount((prevCount) => prevCount + 1);
    setTimeForPractice({
      ...timeForPractice,
      prepare: { status: false, time: "00:00" },
      original: { status: false, time: "00:00" },
      isRunning: false,
      startRecord: false,
    });

    if (questionsBy_module_name.length > 0) {
      if (questionsBy_module_name[index_for_question_destructure - 1]) {
        navigate(
          `/practice/pte/${questionsBy_module_name[index_for_question_destructure - 1]
            .module_name
          }/${questionsBy_module_name[index_for_question_destructure - 1].id}`
        );
        setData({
          ...data,
          index_for_question_destructure: index_for_question_destructure - 1,
        });
      }
    }
  };

  const add_Practice_answer = async (data) => {
    setloader(false)
    if (data) {
      await api
        .add_practice_result({
          ...answerJson,
          // ...data,
          api_response: "",
          audio_url: data,
          exam_type: selected_ques_Obj.exam_type,
          qst_id: selected_ques_Obj._id,
          user_id: JSON.parse(userData)?._id,
        })
        .then((res) => {
          if (res.status) {
            setisSubmitted(true)
            // Swal.fire({
            //   icon: "success",
            //   title: "Saved",
            //   text: "Answer Saved Successfully",
            // });
            setRedoCount((prevCount) => prevCount + 1);
          } else {
            setRedoCount((prevCount) => prevCount + 1);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      var answer = "";
      if (window.location.pathname.includes("pte/ro")) {
        // original
        // var answerNode = document.getElementById("rightId").children;

        // for (let i = 0; i < answerNode.length; i++) {
        //   var ans = answerNode[i].children[0].getAttribute(
        //     "data-rbd-drag-handle-draggable-id"
        //   );
        //   ans = ans.replace("content-", "");
        //   ans = Number(ans) + 1;
        //   ans = String(ans);
        //   answer += ans + ",";
        // }

        var answerNode = document.getElementsByClassName(
          "smooth-dnd-container vertical"
        )[2].children;
        console.log("answerNode", answerNode);
        for (let i = 0; i < answerNode.length; i++) {
          var ans = answerNode[i].children[0].children[1].innerHTML[0];
          if (i == answerNode.length - 1) {
            answer += ans;
          } else {
            answer += ans + ",";
          }
        }
      } else if (window.location.pathname.includes("pte/fib_rd")) {
        // var answerNode = document.getElementsByClassName("css-1au4hjg");
        var answerNode = document.getElementsByClassName("css-o9l62x");
        console.log("answerNode",answerNode);
        
        for (var i = 0; i < answerNode.length; i++) {
          const ans = answerNode[i]?.children[0]?.children[0]?.innerText
          answer += ans + ",";
        }
      }
      await api
        .add_practice_result({
          ...answerJson,
          answer: answer ? answer : answerJson?.answer,
          audio_url: "",
          api_response: "",
          exam_type: selected_ques_Obj.exam_type,
          qst_id: selected_ques_Obj._id,
          user_id: JSON.parse(userData)?._id,
        })
        .then((res) => {
          if (res.status) {
            setisSubmitted(true)
            setRedoCount((prevCount) => prevCount + 1);
            // Swal.fire({
            //   icon: "success",
            //   title: "Saved",
            //   text: "Answer Saved Successfully",
            // });
            // setRedoCount((prevCount) => prevCount + 1);
          } else {
            setRedoCount((prevCount) => prevCount + 1);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
          console.log("ress", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const submitAnwer = async (e) => {
    setloader(true)
    setisSubmitted(true)
    setTimeForPractice({ ...timeForPractice, isRunning: false, stopped: false });
    if (for_read_alouds_page) {
      console.log("RECORDING BLOB", answerJson?.audioBlob);
      await S3upload(
        answerJson?.audioBlob,
        `practice_answer/${selected_ques_Obj?.id}/${Date.now()}_${JSON.parse(userData)?._id
        }.ogg`
      )
        .then(async (url) => {
          // handleRedo(e);
          add_Practice_answer(url);
          // setAnswerJson({ ...answerJson, audio_url: url });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      // }
    } else {
      add_Practice_answer();
    }
  };
  console.log("SUBMITBUTTONREDO", isSubmitted);
  const handleRedo = (e) => {
    e.preventDefault();
    setisSubmitted((prev) => false)
    if (for_read_alouds_page) {
      setAnswerJson({ ...answerJson, audioBlob: "", audio_url: "" });
      setRedoCount((prevCount) => prevCount + 1);
    } else {
      setAnswerJson({ ...answerJson, answer: "", audio_url: "" });
      setRedoCount((prevCount) => prevCount + 1);
    }
  };
  let name = window.location.hostname.split(".")[0];
  if (name === "localhost") {
    console.log("amnwer.json", answerJson);
  }

  const add_bookmark = async (qst_id, bookmark, ids) => {
    // setfilterObj({ isExplantion: false });
    let authApi = new AuthApi();
    await authApi
      .add_bookmark({
        user_id: JSON.parse(userData)?._id,
        qst_id: qst_id,
        bookmark: !bookmark,
        id: ids,
      })
      .then((res) => {
        if (res.status) {
          fetchData()
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const types = ["Bar", "Line", "Pie", "Flow", "Table", "Map", "Pic", "Comb"];
  var currentDate = new Date();

  var currentMonth = currentDate.toLocaleString("default", { month: "short" });
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">{loadedQuestionObj?.text}</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block>
        <Form action="#">
          {/* <div class="eYAJjz">
                        <div class="bbuErs">
                            <div class="wrapper">
                                <i class="anticon custom-icon " style={{ fontSize: "16px", color: "rgb(255, 255, 255)" }}>
                                    <svg viewBox="0 0 10 6" width="1em" height="1em" fill="currentColor"><path d="M1.06 1.177l4 3.909L9.039 1.06" stroke="#fff" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                </i>
                            </div>
                        </div>
                    </div> */}
          {openQuestionTop === false && (
            <div class="right-btn">
              <div class="right-btn-color">
                <div class="wrapper">
                  <Button
                    className="btn-icon"
                    variant="transparent"
                    onClick={() => setopenQuestionTop(!openQuestionTop)}
                  >
                    {" "}
                    <Icon name="chevron-left" size="lg"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          )}
          <Offcanvas
            scroll={true}
            placement="end"
            className="px-5"
            show={openQuestionTop}
            onHide={() => setopenQuestionTop(false)}
          >
            {openQuestionTop === true && (
              <div class="mid-btn">
                <div class="mid-btn-color">
                  <div class="wrapper">
                    <Button
                      className="btn-icon"
                      variant="transparent"
                      onClick={() => setopenQuestionTop(!openQuestionTop)}
                    >
                      {" "}
                      <Icon name="chevron-right" size="lg"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <div className="d-flex gap-3 align-items-center">
                  <img
                    alt=""
                    src={`/images/practice-icons/${module_name}.svg`}
                    style={{ width: "50px" }}
                  ></img>
                  <h1>{loadedQuestionObj?.text}</h1>
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column justify-content-between">
              <div ref={scrollTop}>
                <div className="practice-que mb-3">
                  <div className="form-control-wrap" style={{ width: "180px" }}>
                    <Select
                      onChange={(e) => {
                        searchQuestion2(e.target.value);
                        if (
                          e.target.value != "new" &&
                          e.target.value != "old"
                        ) {
                          setfilterObj({
                            ...filterObj,
                            islength: e.target.value,
                            type_question: null,
                          });
                        } else {
                          setfilterObj({
                            ...filterObj,
                            islength: null,
                            type_question: e.target.value,
                          });
                        }
                      }}
                      name="type_question"
                      value={filterObj?.type_question}
                    >
                      <option value="new">New</option>
                      <option value="old">Question Number</option>
                      {module_name == "repeat_sentences" && (
                        <>
                          <option value="short">Short</option>
                          <option value="long">Long</option>
                        </>
                      )}
                    </Select>
                  </div>
                  <div className="form-control-wrap" style={{ width: "180px" }}>
                    <Select
                      placeholder="Prac Status"
                      onChange={(e) => {
                        console.log("PRACSTATUS", e.target.value)
                        // searchQuestion2(null, e.target.value);
                        setfilterObj({
                          ...filterObj,
                          ["isPractice"]: e.target.value == "true" ? true : e.target.value == "false" ? false : "all",
                        });
                      }}
                      name="isPractice"
                      value={filterObj?.isPractice?.toString()}
                      // value={filterObj?.isPractice ? "Practiced" : "Unpracticed"}
                      removeItemButton
                    >
                      <option value={false}>Unpracticed </option>
                      <option value={true}>Practiced </option>
                    </Select>
                  </div>
                  <div className="form-control-wrap" style={{ width: "180px" }}>
                    <Select
                      placeholder="Bookmark"
                      onChange={(e) => {
                        searchQuestion2(null, null, null, null, e.target.value);
                        const updatedFilterObj = { ...filterObj };
  
                        if (e.target.value == "true") {
                          updatedFilterObj["isBookMark"] = true;
                        } else if (e.target.value == "false") {
                          updatedFilterObj["isBookMark"] = false;
                        } else {
                          delete updatedFilterObj["isBookMark"];
                        }
                        
                        setfilterObj(updatedFilterObj);
                        // setfilterObj({
                        //   ...filterObj,
                        //   ["isBookMark"]: e.target.value == "true" ? true : e.target.value == "false" ? false : "",
                        // });
                      }}
                    
                      value={filterObj?.isBookMark?.toString()}
                      name="isBookMark"
                      removeItemButton
                    >
                      <option value={true}>Marked </option>
                      <option value={false}>Not Marked </option>
                    </Select>
                  </div>
                  {console.log("filterObj", filterObj)}
                  {(module_name == "retell_lectures" ||
                    module_name == "swts" ||
                    module_name == "fib_wr" ||
                    module_name == "ssts" ||
                    module_name == "fib_rd" ||
                    module_name == "essays" ||
                    module_name == "answer_questions") && (
                      <div
                        className="form-control-wrap"
                        style={{ width: "180px" }}
                      >
                        <Select
                          placeholder="Explanation"
                          // value={filterObj?.isExplantion}
                          onChange={(e) => {
                            searchQuestion2(
                              null,
                              null,
                              null,
                              null,
                              null,
                              e.target.value
                            );
                            setfilterObj({
                              ...filterObj,
                              ["isExplantion"]: e.target.value == "true" ? true : e.target.value == "false" ? false : null,
                            });
                          }}
                          value={filterObj?.isExplantion?.toString()}
                          name="isExplantion"
                          removeItemButton
                        >
                          <option value={true}>Explained </option>
                          <option value={false}>Not Explained </option>
                        </Select>
                      </div>
                    )}
                  {(module_name == "fib_wr" || module_name == "fib_rd") && (
                    <div
                      className="form-control-wrap"
                      style={{ width: "180px" }}
                    >
                      <Select
                        placeholder="Explain.Video"
                        // value={filterObj?.explanatory_video}
                        onChange={(e) => {
                          searchQuestion2(
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            e.target.value
                          );
                          setfilterObj({
                            ...filterObj,
                            ["explanatory_video"]: e.target.value == "true" ? true : false,
                          });
                        }}
                        value={filterObj?.explanatory_video?.toString()}
                        name="explanatory_video"
                        removeItemButton
                      >
                        <option value={true}>Video </option>
                      </Select>
                    </div>
                  )}
                  {module_name == "essays" && (
                    <div
                      className="form-control-wrap"
                      style={{ width: "180px" }}
                    >
                      <Select
                        placeholder="Type"
                        onChange={(e) => {
                          searchQuestion2(
                            null,
                            null,
                            e.target.value,
                            null,
                            null,
                            null
                          );
                          setfilterObj({
                            ...filterObj,
                            ["image_type"]: e.target.value,
                          });
                        }}
                        value={filterObj?.image_type?.toString()}
                        name="image_type"
                        removeItemButton
                      >
                        <option value={"dual_q"}>Dual Q </option>
                        <option value={"y/n"}>Y/N </option>
                        <option value={"open_q"}>Open Q </option>
                      </Select>
                    </div>
                  )}

                  {module_name == "describe_images" && (
                    <div
                      className="form-control-wrap"
                      name="Type"
                      onChange={(e) => {
                        searchQuestion2(null, null, e.target.value);
                        setfilterObj({
                          ...filterObj,
                          ["image_type"]: e.target.value,
                        });
                      }}
                      // value={filterObj?.image_type}
                      style={{ width: "180px" }}
                    >
                      <Select removeItemButton placeholder="Type" value={filterObj?.image_type?.toString()}>
                        {types.map((item) => {
                          return (
                            <>
                              <option value={item}>{item}</option>
                            </>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                  <InputGroup>
                    <input
                      className="form-control"
                      // value={filterObj?.title}
                      onChange={(e) => {
                        searchQuestion2(null, null, null, e.target.value);
                        setfilterObj({
                          ...filterObj,
                          ["title"]: e.target.value,
                        });
                      }}
                      value={filterObj?.title}
                      id="tagClear"
                      placeholder="Question Title/Number"
                      type="text"
                    />
                    <Button onClick={() => { navigate(`/practice/pte/search?model=${module_name}&text=${filterObj.title ? filterObj.title : ""}`) }} variant="outline-primary">
                      <Icon name="search"></Icon>
                    </Button>
                  </InputGroup>
                </div>
                <div className="my-3 d-flex justify-content-between align-items-center">
                  <div></div>
                  <span className="text-secondary">
                    {data?.questionsBy_module_name?.length > 0 && `Done ${data?.isPracticedDoneCount}, Found ${Math.ceil(data?.totalDocuments)} Questions`}

                    {/* {data?.totalDocuments} Questions */}
                  </span>
                </div>

                <Tab.Container
                  id="pills-tabs-example"
                  defaultActiveKey={"AllQuestions"}
                >

                  <Nav
                    variant="pills"
                    className="mb-3 gap-2 justify-content-between"
                  >
                    <div className="d-flex gap-2">
                      <Nav.Item>
                        <Nav.Link
                          eventKey={"AllQuestions"}
                          className="rounded-pill px-3 py-0"
                          style={{ color: navLinks[0] == 0 ? "white" : "black" }}
                          onClick={() => { 
                            setNavLinks([0]) 
                            setfilterObj({...filterObj,isExplantion: null, isPractice: "all", isPrediction:"", isMonthlyPrediction:false})
                          }}

                        // onClick={() => filter_by_Question("all")}
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={"week"}
                          className="rounded-pill px-3 py-0"
                          style={{ color: navLinks[0] == 1 ? "white" : "black" }}
                          onClick={() => { 
                            setNavLinks([1]) 
                            setfilterObj({...filterObj, isPrediction: true, isMonthlyPrediction:false})
                          }}
                        // onClick={() => filter_by_Question("week")}
                        >
                          Weekly Prediction
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={currentMonth}
                          className="rounded-pill px-3 py-0"
                          style={{ color: navLinks[0] == 2 ? "white" : "black" }}
                          onClick={() => { 
                            setNavLinks([2]) 
                            setfilterObj({...filterObj, isMonthlyPrediction:true})
                          }}
                        // onClick={() =>
                        //   filter_by_Question(currentMonth, currentMonth)
                        // }
                        >
                          {currentMonth} Prediction
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                  {console.log(
                    "questionsBy_module_name",
                    questionsBy_module_name
                  )}
                  <Tab.Content>
                    {questionsBy_module_name.length == 0 ? (
                      <li className="d-flex justify-content-between align-items-center">
                        No Record Found
                      </li>
                    ) : (
                      <Tab.Pane
                        eventKey={navLinks[0] == 0 ? "AllQuestions" : navLinks[0] == 1 ? "week" : currentMonth}
                        
                        className="questions-practice"
                      >
                        <ul className="link-list link-list-md link-list-hover-bg-primary">
                          {console.log("questionsBy_module_name",questionsBy_module_name)
                          }
                          {questionsBy_module_name.length > 0 &&
                            questionsBy_module_name.map((item, i) => {
                              return (
                                <>
                                  <li className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                      <Button
                                        variant="transparent"
                                        className="text-secondary h5 justify-content-start"
                                        onClick={() => {
                                          setData({
                                            ...data,
                                            index_for_question_destructure: i,
                                          });
                                          // setTimeForPractice({
                                          //   ...timeForPractice,
                                          //   prepare: {
                                          //     status: true,
                                          //     time:
                                          //       item.answer_prepare_time !=
                                          //         "null" &&
                                          //       item?.answer_prepare_time
                                          //         ? item?.answer_prepare_time
                                          //         : "00:00",
                                          //   },
                                          //   time: {
                                          //     status: false,
                                          //     time:
                                          //       item.answer_time != "null" &&
                                          //       item?.answer_time
                                          //         ? item?.answer_time
                                          //         : "00:00",
                                          //   },
                                          // });
                                          setopenQuestionTop(!openQuestionTop);
                                          navigate(
                                            `/practice/pte/${item?.module_name}/${item.id}`
                                          );
                                        }}
                                        style={{ minWidth: "300px" }}
                                      >
                                        {/* <span>#{item?.id}</span>  */}
                                        <span>{item?.tags} </span>
                                      </Button>
                                      <div className="d-flex gap-2 justify-content-start align-items-center">
                                        <Badge
                                          bg="danger"
                                          className="text-white ms-3"
                                        >
                                          New
                                        </Badge>
                                        {item?.isPrediction && (
                                          <Badge
                                            bg="assigned"
                                            className="text-white ms-3"
                                          >
                                            Prediction
                                          </Badge>
                                        )}{" "}
                                        {item?.isExplained && (
                                          <Badge

                                            className="text-white ms-3"
                                          >
                                            Explain
                                          </Badge>
                                        )}
                                      </div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                      {item?.isPracticeDone ? <Button variant="success" size="sm">
                                        Practice * {item?.isPracticeAttempt}
                                      </Button> : <Button variant="light" size="sm">
                                        Undone
                                      </Button>}

                                      <Button
                                        className="btn-icon"
                                        variant="transparent"
                                        onClick={() =>
                                          add_bookmark(
                                            item?._id,
                                            item?.isBookmark?.bookmark,
                                            item.isBookmark
                                              ? item.isBookmark?._id
                                              : null
                                          )
                                        }
                                      >
                                        {item?.isBookmark?.bookmark == true ? (
                                          <Icon name="bookmark-fill"></Icon>
                                        ) : (
                                          <Icon name="bookmark"></Icon>
                                        )}
                                      </Button>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>

              <div className="d-flex justify-content-between position-relative bottom-0 align-items-center react-pagination">

                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={5}
                  // pageCount={Object.values(filterObj).filter(item => item === true).length > 0 ? (data?.questionsBy_module_name?.length/itemsPerPage) : Math.ceil(data?.totalDocuments / itemsPerPage)}
                  pageCount={Math.ceil(data.totalDocuments / itemsPerPage)}
                  previousLabel="<previous"
                  renderOnZeroPageCount={null}
                  pageClassName="toClick"
                  forcePage={paginate?.currentPage-1}
                // initialPage={0}
                // forcePage={0}
                // forcePage={Object.values(filterObj).filter(item => item === true).length > 0 && 0}
                />

                <div className="d-flex gap-2 align-items-center text-secondary">
                  <span>GO TO </span>
                  <Form.Group className="form-group">
                    <div className="form-control-wrap">
                      <Form.Control
                        name="goToPageNumber"
                        type="text"
                        style={{ width: "80px" }}
                        value={Number(paginate.currentPage)}
                        onChange={(event) =>
                          handlePageChange(event)
                        }
                      />
                    </div>
                  </Form.Group>
                  <span>
                    /{" "}
                    {/* {Math.ceil(Object.values(filterObj).filter(item => item === true).length > 0 ? (data?.questionsBy_module_name?.length/itemsPerPage) : Math.ceil(data?.totalDocuments / itemsPerPage))} */}
                    {Math.ceil(data?.totalDocuments / itemsPerPage)}
                  </span>
                </div>
              </div>

            </Offcanvas.Body>
          </Offcanvas>
          <Card className="card-gutter-md mb-5">
            <Card.Body>
              {selected_ques_Obj && id ? (
                <>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-start">
                      {selected_ques_Obj && (
                        <PracticeTopHeading
                          data={loadedQuestionObj}
                          selected_ques_Obj={selected_ques_Obj}
                        />
                      )}
                    </div>
                    {
                      !openQuestionTop && selected_ques_Obj && (
                        <PracticeTitleTime
                          key={redoCount}
                          selected_ques_Obj={selected_ques_Obj}
                        ></PracticeTitleTime>
                      )
                      //   path.includes("/practice/pte/repeat_sentences")) ||
                      // path.includes("/practice/pte/retell_lectures") ||
                      // path.includes("/practice/pte/answer_questions") ||
                      // path.includes("/practice/pte/ssts") ||
                      // path.includes("/practice/pte/l_mcm") ||
                      // path.includes("/practice/pte/l_mcs") ||
                      // path.includes("/practice/pte/l_fib") ||
                      // path.includes("/practice/pte/l_hcs") ||
                      // path.includes("/practice/pte/l_smw") ||
                      // path.includes("/practice/pte/hiws") ||
                      // path.includes("/practice/pte/wfds") ? (
                      //   <>
                      //     {selected_ques_Obj && (
                      //       <PracticeTitleTimeForAudio
                      //         selected_ques_Obj={selected_ques_Obj}
                      //       ></PracticeTitleTimeForAudio>
                      //     )}
                      //   </>
                      // ) : (
                      //   <PracticeTitleTime
                      //     selected_ques_Obj={selected_ques_Obj}
                      //   ></PracticeTitleTime>
                    }
                  </div>

                  {(path.includes("/practice/pte/read_alouds") ||
                    path.includes("/practice/pte/swts") ||
                    path.includes("/practice/pte/essays")) && (
                      <div className="d-flex flex-column mb-4">
                        {/* <AudioUI></AudioUI> */}
                        <PracticeParagraph
                          selected_ques_Obj={selected_ques_Obj}
                        />
                      </div>
                    )}

                  {/* For Repeat Sentence, Re-Tell Lectures, Answer Short Questions, Listening Summarize Spoken Text, Listening Multichoice Multiple, Listening Multichoice Single, Listening Fill In Blanks, Listening Highlight Correct Summary, Listening Select Missing Word, Listening Highlight Incorrect Words, Listening Write From Dictation  */}
                  {(path.includes("/practice/pte/repeat_sentences") ||
                    path.includes("/practice/pte/retell_lectures") ||
                    path.includes("/practice/pte/answer_questions") ||
                    path.includes("/practice/pte/ssts") ||
                    path.includes("/practice/pte/l_mcm") ||
                    path.includes("/practice/pte/l_mcs") ||
                    path.includes("/practice/pte/l_fib") ||
                    path.includes("/practice/pte/l_hcs") ||
                    path.includes("/practice/pte/l_smw") ||
                    path.includes("/practice/pte/hiws") ||
                    path.includes("/practice/pte/wfds")) && (
                      <>
                        {selected_ques_Obj && !openQuestionTop && (
                          <PracticePlayingAudio
                            play={false}
                            countupdate={redoCount}
                            selected_ques_Obj={selected_ques_Obj}
                          />
                        )}
                      </>
                    )}
                  {/* <div className="practice-textarea d-flex align-items-start gap-2 mb-5"> */}
                  {path.includes("/practice/pte/ro") && (
                    <div className="practice-kanban mb-5">
                      {selected_ques_Obj?.question_options?.length && (
                        <>
                          <ReadingReOrder
                            key={redoCount}
                            data={selected_ques_Obj}
                          ></ReadingReOrder>
                        </>
                      )}
                    </div>
                  )}

                  {path.includes("/practice/pte/l_fib") && (
                    <PracticePteListeningFIB
                      showAnwer={switchChecked}
                      data={selected_ques_Obj}
                      key={redoCount}
                    />
                  )}
                  {/*  </div> */}
                  {/* For Reading Multichoice Multi, Listening Multichoice Multi */}
                  {(path.includes("/practice/pte/l_mcm") ||
                    path.includes("/practice/pte/r_mcm")) && (
                      <div className="practice-textarea gap-2 mb-5">
                        <p className="practice-pte-question-newline">{selected_ques_Obj?.question_content}</p><br />

                        {selected_ques_Obj && (
                          <InputCheckbox
                            showAnwer={switchChecked}
                            key={redoCount}
                            question_content={selected_ques_Obj?.question}
                            redoCount={redoCount}
                            data={selected_ques_Obj?.question_options}
                          />
                        )}
                      </div>
                    )}

                  {/* For Reading Multichoice Single, Listening Multichoice Single, Listening Highlight Correct Summary, Listening Select Missing Word */}
                  {(path.includes("/practice/pte/l_mcs") ||
                    path.includes("/practice/pte/r_mcs") ||
                    path.includes("/practice/pte/l_hcs") ||
                    path.includes("/practice/pte/l_smw")) && (
                      <div className="practice-textarea gap-2 mb-5">
                        {selected_ques_Obj && (
                          <PracticePteReadingMultiChoiceRadio
                            showAnwer={switchChecked}
                            key={redoCount}
                            redoCount={redoCount}
                            data={selected_ques_Obj}
                          />
                        )}
                      </div>
                    )}

                  {/* For Listening Highlight Incorrect Words */}
                  {path.includes("/practice/pte/hiws") && (
                    <PracticePteListeningHIWS
                      showAnwer={switchChecked}
                      selected_ques_Obj={selected_ques_Obj}
                      key={redoCount}
                    />
                  )}

                  {/* For Reading and Writing */}
                  {path.includes("/practice/pte/fib_wr") && (
                    <>
                      {/* <ReadWriteFillinBlanks data={selected_ques_Obj} /> */}
                      <PracticeFIBRW
                        data={selected_ques_Obj}
                        redoCount={redoCount}
                        switchChecked={switchChecked}
                      ></PracticeFIBRW>
                    </>
                  )}

                  {/* For Reading FIB */}
                  {console.log("APLEEEEEEEE")}
                  {path.includes("/practice/pte/fib_rd") && (
                    <>
                      {selected_ques_Obj && (
                        <>
                          {/* <ReadingFillInBlanks data={selected_ques_Obj} /> */}
                          <PracticePteReadingFIB
                            data={selected_ques_Obj}
                            key={redoCount}
                            switchChecked={switchChecked}
                          />
                        </>
                      )}
                    </>
                  )}

                  {/* Textarea for Summarize Written Text, Write Essay, Summarize Spoken Text, Write From Dictation */}
                  {(path.includes("/practice/pte/swts") ||
                    path.includes("/practice/pte/essays") ||
                    path.includes("/practice/pte/ssts") ||
                    path.includes("/practice/pte/wfds")) && (
                      <>
                        <PracticeTextArea key={redoCount} isSubmitted={isSubmitted} handleRedo={handleRedo} getQuestionBy_id={getQuestionBy_id}></PracticeTextArea>
                        <PracticeResult />
                      </>
                    )}

                  {/* For Describe Image */}
                  {path.includes("/practice/pte/describe_images") && (
                    <PracticeDescribeImage
                      key={redoCount}
                      selected_ques_Obj={selected_ques_Obj}
                    />
                  )}

                  {/* For Read Aloud, Repeat Sentence, Describe Image, Re-Tell Lectures, Answer Short Questions */}
                  {for_read_alouds_page && (
                    <div className="microphone-answer">
                      {selected_ques_Obj && (
                        <PracticeMicrophone
                          key={redoCount}
                          setRedoCount={setRedoCount}
                          data={selected_ques_Obj}
                          isSubmitted={isSubmitted}
                        ></PracticeMicrophone>
                      )}
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex gap-3">
                        {
                          isSubmitted ?
                            <SubmitButton text="Submitted" disabled={true} color="rgba(0,0,0,.25)" borderColor="#d9d9d9" backgroundColor="#f5f5f5" cursorNotAllowed="not-allowed" loader={loader} />
                            :
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={submitAnwer}
                              className={`${
                                window.location.pathname.includes("pte/ro") ||
                                window.location.pathname.includes("pte/fib_rd")
                                  ? ""
                                  : (for_read_alouds_page && !answerJson?.audioBlob) ||
                                    (!for_read_alouds_page && !answerJson?.answer)
                                  ? "pe-auto"
                                  : ""
                              }`}
                              disabled={
                                window.location.pathname.includes("pte/ro") ||
                                window.location.pathname.includes("pte/fib_rd")
                                  ? false
                                  : (for_read_alouds_page && !answerJson?.audioBlob) ||
                                    (!for_read_alouds_page && !answerJson?.answer)
                              }
                              style={{
                                cursor: window.location.pathname.includes("pte/ro") ||
                                window.location.pathname.includes("pte/fib_rd")
                                  ? "pointer"
                                  : (for_read_alouds_page && !answerJson?.audioBlob) ||
                                    (!for_read_alouds_page && !answerJson?.answer)
                                  ? "not-allowed"
                                  : "default"
                              }}
                            >
                              Submit
                            </Button>

                        }
                        <Button
                          variant="assigned"
                          size="sm"
                          // disabled={
                          //   (for_read_alouds_page && !answerJson?.audioBlob) ||
                          //   (!for_read_alouds_page && !answerJson.answer)
                          // }
                          onClick={(e) => {
                            handleRedo(e);
                            getQuestionBy_id(id);
                          }}
                        >
                          Re-do
                        </Button>
                        {/* <Button variant="outline-primary" size="sm">
                          <Icon name="copy"></Icon> Dict Mode
                        </Button> */}
                        <div className="d-flex gap-2 align-items-center">
                          Answer{" "}
                          <Form.Check
                            type="switch"
                            id="checkboxGoogle"
                            onChange={handleSwitchChange}
                            checked={switchChecked}
                          />
                        </div>
                      </div>

                      <div className="d-flex gap-3 align-items-center">
                        <input
                          className="form-control"
                          // value={searchedText}
                          onChange={(e) => {
                            searchQuestion2(
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              e.target.value
                            );
                            setfilterObj({
                              ...filterObj,
                              inputSearch: e.target.value,
                            });
                          }}
                          placeholder="Question Title/Number"
                          type="text"
                          style={{ width: "200px" }}
                        />
                        <Button
                          variant="assigned"
                          size="sm"
                          onClick={() => {
                            navigate(`/practice/pte/search?model=${module_name}&text=${filterObj.inputSearch ? filterObj.inputSearch : ""}`)
                          }}
                        >
                          Search
                        </Button>
                        <Button
                          variant="assigned"
                          onClick={PreviousQuestion}
                          size="sm"
                          disabled={index_for_question_destructure === 0}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="assigned"
                          onClick={nextQuestion}
                          size="sm"
                          disabled={
                            index_for_question_destructure ===
                            questionsBy_module_name.length - 1
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>

                    {console.log("Swticheddd", switchChecked)}
                    {switchChecked && (
                      <>
                        {" "}
                        <div className="my-4 border rounded p-3">
                          {/* {sample_answer && sample_answer != "null" && (
                            <>
                              <h5>Sample Answer :</h5>
                              <p className="text-secondary">{sample_answer}</p>
                            </>
                          )} */}

                          {(selected_ques_Obj?.module_name?.includes("mcm") || selected_ques_Obj?.module_name?.includes("mcs") || selected_ques_Obj?.module_name?.includes("smw") || selected_ques_Obj?.module_name?.includes("swts") || selected_ques_Obj?.module_name?.includes("essays") || selected_ques_Obj?.module_name?.includes("describe_images") || selected_ques_Obj?.module_name?.includes("answer_questions")  ) ? sample_answer && sample_answer != "null" && (
                            <>
                              {/* Sample Answer */}
                              <h5 className="mb-3">Answer :</h5>
                              <p className="text-secondary line-height-2 white-space-break-spaces mb-3">{sample_answer}</p>
                            </>
                          ) : (answer && answer != "null" && selected_ques_Obj?.module_name != "wfds") && (
                            <>
                              <h5 className="mb-3">Answer :</h5>
                              {/* <p className="text-secondary">{answer}</p> */}
                              <p className="text-secondary line-height-2 white-space-break-spaces mb-3">{selected_ques_Obj?.module_name == "ro" ? answer : answer.split(',').map((word, index) => `${index + 1}. ${word}`).join(', ')}</p>
                            </>
                          )}

                          {/* for read alouds answers  */}
                          {selected_ques_Obj?.module_name?.includes("read_alouds") && answer_content_audio && 
                            <>
                            <h5 className="mb-3">Answer :</h5>
                            <audio style={{ width: "25%" }} controls>
                              <source src={answer_content_audio}></source>
                            </audio>
                            </>
                          }
                            

                          {question_transcript &&
                            question_transcript != "null" && (
                              <>
                                <h5 className="mb-3">Transcript :</h5>
                                <p className="text-secondary line-height-2 white-space-break-spaces mb-3">
                                  {question_transcript}
                                </p>
                              </>
                            )}

                          {question_explanation &&
                            question_explanation != "null" && (
                              <>
                                <h5 className="mb-3">Explantion :</h5>
                                <p className="text-secondary line-height-2 white-space-break-spaces mb-3">
                                  {question_explanation}
                                </p>
                              </>
                            )}
                        </div>
                      </>
                    )}
                  </div>
                  <PracticePteComments
                    redoCount={redoCount}
                    qst_id={selected_ques_Obj._id}
                    user_id={JSON.parse(userData)._id}
                    answerSubmitted={isSubmitted}
                  />
                </>
              ) : (
                // "No Data Found" message
                <p>No Data Found</p>
              )}
            </Card.Body>
          </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default PracticePte;
