import React, { useRef, useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
function VolumeUI() {
  const audioRef = React.createRef();
  const [count, setCount] = useState(100);
  const [isMuted, setIsMuted] = useState(false);
  const handleVolumeChange = (value) => {
    setCount(Number(value));
    audioRef.current.volume = isMuted ? 0 : Number(value) / 100;
  };
  return (
    <>
      <p className='w-100 text-secondary exam-vol'>Volume :
        <input
          className='w-100'
          type="range"
          // value={count}
          // onChange={(e) => handleVolumeChange(e.target.value)}
          min="0"
          max="100"
          step="1"
          id="volume-control"
        />
        {/* <input className='w-100'
        type="range"
        value={count}
        onChange={(e) => handleVolumeChange(e.target.value)}
        min="0"
        max="100"
        step="1"
        id="volume-control"
      /> */}
      </p>
    </>
  );
}

export default VolumeUI;
