import * as React from 'react';
import { Card, Button, Form, Row, Col, Nav, Tab, Tabs, Badge } from 'react-bootstrap';
import { Icon } from '../../../components';
function SampleAnswerCheckbox() {

  return (
    <div className='bg-white border rounded h-100'>
      <div className='bg-hilight text-secondary p-3'>Sample Answer</div>
      <div className='p-3 answer-checkbox'>
        <Form.Check type="checkbox" id="checkbox-A" label="Since many in the country can  speak and understand English, the language in important for us." />

        <Form.Check type="checkbox" id="checkbox-B" checked label="The literary important of English cannot be underestimated." />

        <Form.Check type="checkbox" id="checkbox-C" checked label="Language plays an important role in research and Development " />

        <Form.Check type="checkbox" id="checkbox-D" label="English has today become the language of the words. " />
      </div>
    </div>
  );
}
export default SampleAnswerCheckbox;